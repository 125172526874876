// Redux
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
// Typings
import { RootState } from "store";
import { StateProps, Study } from "./types";
// Schemas
import { StudySchema } from "./schemas";
// Initial State
import { initialState } from "./initial-state";
// Side Effects
import {
  deleteStudyHistorySideEffect,
  updateStudyHistorySideEffect,
} from "store/modules/study/actions.side-effects";
// Main Api
import { getMainApi } from "store/utils/main-api";
// Zod
import { z } from "zod";

/**
 * Fetch Study by Id
 * Example of an async action / thunk
 * @example await/void dispatch(getStudy({ mainApi, studyId }}));
 */
export const getStudy = createAsyncThunk<
  Partial<StateProps>,
  { studyId: string },
  { state: RootState }
>("study/fetch", async ({ studyId }) => {
  let data: StateProps["data"] = initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: Study }>>({
    schema: z.object({
      status: z.literal(200),
      body: StudySchema,
    }),
    skipParsing: false,
    method: "GET",
    path: `/studies/${studyId}`,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = result.response.body;
  }
  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Update Study description
 * Example of an async action / thunk
 * @example await/void dispatch(updateStudyDescription({ mainApi, studyId, description }}));
 */
export const updateStudyDescription = createAsyncThunk<
  Partial<StateProps>,
  {
    studyId: string;
    description: string;
  },
  { state: RootState }
>("study/update-description", async ({ studyId, description }, { dispatch }) => {
  let data: StateProps["data"] = initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: Study }>>({
    schema: z.object({
      status: z.literal(200),
      body: StudySchema,
    }),
    skipParsing: false,
    method: "PUT",
    path: `/studies/${studyId}/description`,
    body: description,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = result.response.body;

    // Side Effects
    if (data) dispatch(updateStudyHistorySideEffect(data));
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Delete Study description
 * Example of an async action / thunk
 * @example await/void dispatch(updateStudyDescription({ mainApi, studyId, description }}));
 */
export const deleteStudy = createAsyncThunk<
  Partial<StateProps>,
  {
    studyId: string;
  },
  { state: RootState }
>("study/delete", async ({ studyId }, { dispatch }) => {
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200 }>>({
    schema: z.object({
      status: z.literal(200),
    }),
    skipParsing: false,
    method: "DELETE",
    path: `/studies/${studyId}`,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    // Side Effects
    dispatch(deleteStudyHistorySideEffect({ id: studyId }));
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    error,
  };
});

/**
 * Bookmark Study
 * Example of an async action / thunk
 * @example await/void dispatch(bookmarkStudy({ mainApi, studyId }}));
 */
export const bookmarkStudy = createAsyncThunk<
  Partial<StateProps>,
  {
    studyId: string;
  },
  { state: RootState }
>("study/bookmark", async ({ studyId }) => {
  let data: StateProps["data"] = initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: Study }>>({
    schema: z.object({
      status: z.literal(200),
      body: StudySchema,
    }),
    skipParsing: false,
    method: "PUT",
    path: `/studies/${studyId}/bookmark`,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = result.response.body;
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Unbookmark Study
 * Example of an async action / thunk
 * @example await/void dispatch(bookmarkStudy({ mainApi, studyId }}));
 */
export const unbookmarkStudy = createAsyncThunk<
  Partial<StateProps>,
  {
    studyId: string;
  },
  { state: RootState }
>("study/unbookmark", async ({ studyId }) => {
  let data: StateProps["data"] = initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: Study }>>({
    schema: z.object({
      status: z.literal(200),
      body: StudySchema,
    }),
    skipParsing: false,
    method: "PUT",
    path: `/studies/${studyId}/unbookmark`,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = result.response.body;
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Reset Study
 * @example dispatch(resetStudy());
 */
export const resetStudy = createAction("study/reset");
