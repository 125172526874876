import { WebSocketConnectionConfig } from "store/middleware/websockets/types";
import { disconnectUserInterviewsWS } from "../actions";
import { userInterviewMessageSchema } from "./types";

export const onInvalidMessageReceived: WebSocketConnectionConfig<
  typeof userInterviewMessageSchema
>["onInvalidMessageReceived"] = (store, resourceId) => {
  if (resourceId) {
    store.dispatch(disconnectUserInterviewsWS(resourceId));
  }
};
