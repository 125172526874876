// Redux toolkit
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
// Typings
import { RootState } from "store";
// Initial State
import { initialState } from "../initial-state";
// Main Api
import { getMainApi } from "store/utils/main-api";
// Zod
import { z } from "zod";
// Actions
import { getAudiences } from "store/modules/audiences/actions/index";
import { getProblems } from "store/modules/problems/actions";
import { getSolutions } from "store/modules/solutions/actions/index";
import { ProjectSchema } from "../schemas";
import { StateProps, Project, ProjectSettings } from "../types";
import { resetInsightsData } from "store/utils/reset-insights-data";
import { resetCustomScript } from "store/modules/custom-script/actions";
import { resetResearchGoal } from "store/modules/research-goal/actions";
import { updateAudiencesProjectSideEffect } from "store/modules/audiences/actions/side-effects";
import { updateProblemsProjectSideEffect } from "store/modules/problems/actions/side-effects";
import { updateSolutionsProjectSideEffect } from "store/modules/solutions/actions/side-effects";
import { getHistory } from "store/modules/history/actions";
import { getProjectSurveys } from "store/modules/surveys/actions";

/**
 * Fetch Project By Id
 * Example of an async action / thunk
 * @example await/void dispatch(getProjectById({ mainApi, projectId }));
 */
export const getProjectById = createAsyncThunk<
  { data: StateProps["data"]; error: StateProps["error"] },
  { projectId: string; onSuccess?: (newProjectName?: string) => void },
  { state: RootState }
>("projects/fetch-by-id", async ({ projectId, onSuccess }, { getState, dispatch }) => {
  let data: StateProps["data"] = getState().projects.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  resetInsightsData();
  dispatch(resetCustomScript());
  dispatch(resetResearchGoal());

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: Project }>>({
    schema: z.object({
      status: z.literal(200),
      body: ProjectSchema,
    }),
    skipParsing: false,
    method: "GET",
    // TODO: Ask BE if this endpoint should be changed
    path: `/projects/project/${projectId}`,
  });

  if (result.failure) {
    error = result.failure;
    dispatch(updateAudiencesProjectSideEffect({ error }));
    dispatch(updateProblemsProjectSideEffect({ error }));
    dispatch(updateSolutionsProjectSideEffect({ error }));
  } else {
    const project = result.response.body;

    data = {
      project,
    };

    dispatch(updateAudiencesProjectSideEffect({ audiences: project.syntheticUsers }));
    dispatch(updateProblemsProjectSideEffect({ problems: project.problems }));
    dispatch(updateSolutionsProjectSideEffect({ solutions: project.solutions }));
    dispatch(getHistory({ projectId: project.id }));
    dispatch(getProjectSurveys({ projectId }));

    onSuccess && onSuccess(project.name);
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Add Project
 * Example of an async action / thunk
 * @example await/void dispatch(addProject({ mainApi, description }));
 */
export const addProject = createAsyncThunk<
  { data: StateProps["data"]; error: StateProps["error"] },
  {
    name: string;
    description?: string;
    workspaceId: string;
    onSuccess: (projectId: string) => void;
  },
  { state: RootState }
>("projects/add", async ({ name, description, workspaceId, onSuccess }, { getState, dispatch }) => {
  let data: StateProps["data"] = getState().projects.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: Project }>>({
    schema: z.object({
      status: z.literal(200),
      body: ProjectSchema,
    }),
    skipParsing: false,
    method: "POST",
    path: `/projects`,
    body: {
      name,
      description,
      workspaceId,
    },
  });

  if (result.failure) {
    error = result.failure;
  } else {
    const newProject = result.response.body;

    data = {
      project: newProject,
      projectsList: [newProject, ...(data.projectsList || [])],
      projectMembers: undefined,
    };

    dispatch(getHistory({ projectId: newProject.id }));

    onSuccess(newProject.id);
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Edit Project Description
 * Example of an async action / thunk
 * @example await/void dispatch(updateProjectDescription({ mainApi, projectId, description }));
 */
export const updateProjectDescription = createAsyncThunk<
  { data: StateProps["data"]; error: StateProps["error"] },
  {
    projectId: string;
    name: string;
    description?: string;
    workspaceId: string;
    onSuccess: () => void;
  },
  { state: RootState }
>(
  "projects/update-description",
  async ({ projectId, name, description, workspaceId, onSuccess }, { getState }) => {
    let data: StateProps["data"] = getState().projects.data || initialState.data;
    let error: StateProps["error"] = initialState.error;

    const mainApi = getMainApi();

    const result = await mainApi.fetch<z.ZodType<{ status: 200; body: Project }>>({
      schema: z.object({
        status: z.literal(200),
        body: ProjectSchema,
      }),
      skipParsing: false,
      method: "PUT",
      // TODO: Ask BE if this endpoint should be changed
      path: `/projects/project/${projectId}/description`,
      body: {
        description,
        name,
        workspaceId,
      },
    });

    if (result.failure) {
      error = result.failure;
    } else {
      const newProject = result.response.body;
      data = {
        project: newProject,
        projectsList: data.projectsList?.map((project) =>
          project.id === projectId ? newProject : project
        ),
      };

      onSuccess();
    }

    // The value we return becomes the `fulfilled` action payload
    return {
      data,
      error,
    };
  }
);

/**
 * Delete Project
 * Example of an async action / thunk
 * @example await/void dispatch(deleteProject({ mainApi, projectId }));
 */
export const deleteProject = createAsyncThunk<
  { data: StateProps["data"]; error: StateProps["error"] },
  { projectId: string; onSuccess?: (newProjectName?: string) => void },
  { state: RootState }
>("projects/delete", async ({ projectId, onSuccess }, { getState }) => {
  let data: StateProps["data"] = getState().projects.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const projectsList = getState().projects.data.projectsList;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200 }>>({
    schema: z.object({
      status: z.literal(200),
    }),
    skipParsing: false,
    method: "DELETE",
    path: `/projects/project/${projectId}`,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    const remainingProjects = projectsList?.filter((project) => project.id !== projectId);
    const projectToLoad = remainingProjects && remainingProjects[0];

    data = {
      project: projectToLoad,
      projectsList: remainingProjects,
      projectMembers: undefined,
    };

    onSuccess && onSuccess(projectToLoad?.name);
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Delete Project
 * Example of an async action / thunk
 * @example await/void dispatch(deleteProject({ mainApi, projectId }));
 */
export const loadProjectExample = createAsyncThunk<
  Partial<StateProps>,
  { projectId: string; exampleId: string },
  { state: RootState }
>("projects/load-example", async ({ projectId, exampleId }, { dispatch }) => {
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch({
    schema: z.object({
      status: z.literal(200),
      body: z.unknown(),
    }),
    method: "GET",
    path: `/projects/${projectId}/examples/${exampleId}`,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    dispatch(getProblems({ projectId }));
    dispatch(getSolutions({ projectId }));
    dispatch(getAudiences({ projectId }));
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    error,
  };
});

/**
 * Set Selected Solution
 * @example dispatch(setSelectedSolution(solutionId));
 */
export const setProjectSettings = createAction<ProjectSettings>("projects/set-settings");

/**
 * Reset User Interviews
 * @example dispatch(resetProject());
 */
export const resetProject = createAction("projects/reset");

export const resetProjectSettings = createAction("projects/reset-settings");

export * from "./project-members";
