import { WebSocketConnectionConfig } from "store/middleware/websockets/types";
import { streamUserInterviewsConversations } from "../actions";
import { UserInterviewConversationMessageSchema } from "../schemas";

export const onMessage: WebSocketConnectionConfig<
  typeof UserInterviewConversationMessageSchema
>["onMessage"] = (message, store, resourceId) => {
  if (resourceId) {
    store.dispatch(streamUserInterviewsConversations({ clientSideId: resourceId, message }));
  }
};
