import { WebSocketConnectionConfig } from "store/middleware/websockets/types";
import { streamSummaryConversations } from "../actions";
import { SummaryConversationMessageSchema } from "../schemas";

export const onMessage: WebSocketConnectionConfig<
  typeof SummaryConversationMessageSchema
>["onMessage"] = (message, store, resourceId) => {
  if (resourceId) {
    store.dispatch(streamSummaryConversations({ clientSideId: resourceId, message }));
  }
};
