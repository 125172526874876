// Redux
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
// Typings
import { RootState } from "store";
import { GeneratedSyntheticUser, StateProps } from "./types";
// Schemas
import { GeneratedSyntheticUserSchema } from "./schemas";
// Initial State
import { initialState } from "./initial-state";
// Main Api
// Zod
import { getAnalytics } from "logic/analytics/analytics";
import { getMainApi } from "store/utils/main-api";
import { z } from "zod";

/**
 * Generate Synthetic Users
 * Example of an async action / thunk
 * @example await/void dispatch(generateSyntheticUsers({ mainApi, projectId, quantity, audiencesIds, problemsIds }}));
 */
export const generateSyntheticUsers = createAsyncThunk<
  Partial<StateProps>,
  {
    projectId: string;
    quantity: string;
    audiencesIds: string[];
    problemsIds: string[];
    researchGoalId?: string;
  },
  { state: RootState }
>(
  "synthetic-users/generate",
  async ({ projectId, quantity, audiencesIds, problemsIds }, { getState }) => {
    let data: StateProps["data"] = getState().syntheticUsers.data || initialState.data;
    let error: StateProps["error"] = initialState.error;

    const analytics = getAnalytics();
    const mainApi = getMainApi();

    analytics.track("workspace:generate-synthetic-users", {
      project_id: projectId,
      quantity: quantity,
      audiences: audiencesIds,
      problems: problemsIds,
      solution: getState().solutions.data?.selectedSolutionId ?? "",
      research_goal: getState().researchGoal.data?.description ?? "",
      custom_script: getState().customScript.data ?? "",
      study_strategy: getState().study.data?.studyStrategy ?? "",
    });

    const inputsBody = {
      syntheticUsersIds: audiencesIds,
      problemsIds: problemsIds,
      solutionId: getState().solutions.data.selectedSolutionId?.[0] ?? null,
      researchGoalId: getState().researchGoal.data?.id ?? null,
    };

    const createdObjects = await mainApi.fetch<
      z.ZodType<{ status: 200; body: { ids: Record<string, string[]> } }>
    >({
      schema: z.object({
        status: z.literal(200),
        body: z.object({
          ids: z.record(z.string(), z.array(z.string())),
        }),
      }),
      skipParsing: false,
      method: "POST",
      path: `/generateSyntheticUsers/${projectId}`,
      body: inputsBody,
      queryParams: { quantity },
    });

    if (createdObjects.failure) {
      error = createdObjects.failure;
      return {
        data,
        error,
      };
    }

    const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

    // Total two minutes (60 * 2000msecs)
    let i = 12;
    while (i) {
      await sleep(10000);
      const result = await mainApi.fetch<
        z.ZodType<{ status: 200; body: GeneratedSyntheticUser[] | { status: string } }>
      >({
        schema: z.object({
          status: z.literal(200),
          body: z.union([
            z.array(GeneratedSyntheticUserSchema),
            z.object({
              status: z.string(),
            }),
          ]),
        }),
        skipParsing: false,
        method: "POST",
        path: `/generateSyntheticUsers/${projectId}/done`,
        body: createdObjects.response.body,
      });

      if (result.failure) {
        error = result.failure;
        break;
      } else {
        const payload = result.response.body as { status: string };
        if (payload.status === "not-ready") {
          i--;
        } else {
          data = result.response.body as GeneratedSyntheticUser[];
          break;
        }
      }
    }

    // The value we return becomes the `fulfilled` action payload
    return {
      data,
      error,
    };
  }
);

/**
 * Update Synthetic User
 * Example of an async action / thunk
 * @example await/void dispatch(addAudience({ mainApi, syntheticUserId, data }}));
 */
export const updateSyntheticUsers = createAsyncThunk<
  Partial<StateProps>,
  {
    syntheticUserId: string;
    data: string;
  },
  { state: RootState }
>("synthetic-users/update", async ({ syntheticUserId, data: newParam }, { getState }) => {
  let data: StateProps["data"] = getState().syntheticUsers.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: GeneratedSyntheticUser }>>({
    schema: z.object({
      status: z.literal(200),
      body: GeneratedSyntheticUserSchema,
    }),
    skipParsing: false,
    method: "PATCH",
    path: `/generateSyntheticUsers/update/${syntheticUserId}`,
    body: newParam,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = data?.map((item) => (item.id === syntheticUserId ? result.response.body : item));
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Reset Synthetic Users
 * @example dispatch(resetSyntheticUsers());
 */
export const resetSyntheticUsers = createAction("synthetic-users/reset");

/**
 * Generate Synthetic Users
 * Example of an async action / thunk
 * @example await/void dispatch(generateSyntheticUsers({ mainApi, projectId, quantity, audiencesIds, problemsIds }}));
 */
export const generateSyntheticUsersNew = createAsyncThunk<
  Partial<StateProps>,
  {
    projectId: string;
    quantity: string;
    audiences: string[];
    problems: string[];
  },
  { state: RootState }
>(
  "synthetic-users/generate",
  async ({ projectId, quantity, audiences, problems }, { getState }) => {
    let data: StateProps["data"] = getState().syntheticUsers.data || initialState.data;
    let error: StateProps["error"] = initialState.error;

    const analytics = getAnalytics();
    const mainApi = getMainApi();

    analytics.track("workspace:generate-synthetic-users", {
      project_id: projectId,
      quantity: quantity,
      audiences: audiences,
      problems: problems,
      solution: getState().solutions.data?.selectedSolutionId ?? "",
      research_goal: getState().researchGoal.data?.description ?? "",
      custom_script: getState().customScript.data ?? "",
      study_strategy: getState().study.data?.studyStrategy ?? "",
    });

    const createdObjects = await mainApi.fetch<
      z.ZodType<{ status: 200; body: { ids: Record<string, string[]> } }>
    >({
      schema: z.object({
        status: z.literal(200),
        body: z.object({
          ids: z.record(z.string(), z.array(z.string())),
        }),
      }),
      skipParsing: false,
      method: "POST",
      path: `/generateSyntheticUsers/assistant/${projectId}`,
      body: {
        // TODO: Ask backend to change the name of the field
        syntheticUsersDescriptions: audiences,
        problemsDescriptions: problems,
      },
      queryParams: { quantity },
    });

    if (createdObjects.failure) {
      error = createdObjects.failure;
      return {
        data,
        error,
      };
    }

    const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

    // Total two minutes (60 * 2000msecs)
    let i = 12;
    while (i) {
      await sleep(10000);
      const result = await mainApi.fetch<
        z.ZodType<{ status: 200; body: GeneratedSyntheticUser[] | { status: string } }>
      >({
        schema: z.object({
          status: z.literal(200),
          body: z.union([
            z.array(GeneratedSyntheticUserSchema),
            z.object({
              status: z.string(),
            }),
          ]),
        }),
        skipParsing: false,
        method: "POST",
        path: `/generateSyntheticUsers/${projectId}/done`,
        body: createdObjects.response.body,
      });

      if (result.failure) {
        error = result.failure;
        break;
      } else {
        const payload = result.response.body as { status: string };
        if (payload.status === "not-ready") {
          i--;
        } else {
          data = result.response.body as GeneratedSyntheticUser[];
          break;
        }
      }
    }

    // The value we return becomes the `fulfilled` action payload
    return {
      data,
      error,
    };
  }
);
