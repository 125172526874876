import { User } from "firebase/auth";
import {
  createContext,
  MutableRefObject,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { TransportPhase } from "../internals/transports/transported-data/transport-phases";
import { TransportedData } from "../internals/transports/transported-data/transported-data-types";
import { throwError } from "../internals/utils/throw-error";

type AuthSession = TransportedData<{
  user: User;
}>;

type AuthStateContextValue = {
  session: AuthSession;
  sessionRef: MutableRefObject<AuthSession>;
  setSession: (nextValue: AuthSession | ((previousValue: AuthSession) => AuthSession)) => void;
};

const AuthStateContext = createContext<null | AuthStateContextValue>(null);

export function AuthStateProvider(props: { children: ReactNode }) {
  const [session, _setSession] = useState<AuthSession>({
    status: TransportPhase.NotInitialized,
  });
  const sessionRef = useRef(session);
  sessionRef.current = session;

  const setSession = useCallback(
    (nextValue: AuthSession | ((previousValue: AuthSession) => AuthSession)) => {
      if (typeof nextValue === "function") {
        setSession((previousValue) => {
          const value = nextValue(previousValue);
          sessionRef.current = value;

          return value;
        });
      } else {
        _setSession(nextValue);
        sessionRef.current = nextValue;
      }
    },
    []
  );

  const contextValue = useMemo((): AuthStateContextValue => {
    return {
      session,
      sessionRef,
      setSession,
    };
  }, [session, setSession]);

  return (
    <AuthStateContext.Provider value={contextValue}>{props.children}</AuthStateContext.Provider>
  );
}

export function useAuthState() {
  return useContext(AuthStateContext) || throwError();
}
