import { WebSocketConnectionConfig } from "store/middleware/websockets/types";
import { onClose } from "./on-close";
import { onInvalidMessageReceived } from "./on-invalid-message-recieved";
import { onMessage } from "./on-message";
import { UserInterviewConversationMessageSchema } from "../schemas";

export const userInterviewConversationsWSConnectionConfig: WebSocketConnectionConfig<
  typeof UserInterviewConversationMessageSchema
> = {
  path: "/conversations/stream/",
  messageSchema: UserInterviewConversationMessageSchema,
  onMessage: onMessage,
  onClose: onClose,
  onInvalidMessageReceived: onInvalidMessageReceived,
};
