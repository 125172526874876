// Redux toolkit
import { createAction } from "@reduxjs/toolkit";

export const resetCustomScript = createAction("custom-script/reset");

export const addCustomScript = createAction(
  "custom-script/add",
  function prepare(customScript: string) {
    return {
      payload: {
        customScript,
        questionsNumber: customScript?.split("\n").length,
      },
    };
  }
);
