import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { initialState } from "./initial-state";
import { StateProps } from "./types";

import { uploadRagFile, resetRag, getRagFiles, deleteRagFile } from "./actions";

export const reducer = createReducer<StateProps>(initialState, (builder) => {
  // Reset sso provider
  builder.addCase(resetRag, () => initialState);

  builder
    .addMatcher(
      isAnyOf(uploadRagFile.pending, getRagFiles.pending, deleteRagFile.pending),
      (state) => ({
        ...state,
        loading: true,
      })
    )
    .addMatcher(
      isAnyOf(uploadRagFile.fulfilled, getRagFiles.fulfilled, deleteRagFile.fulfilled),
      (state, action) => ({
        ...state,
        ...action.payload,
      })
    )
    .addMatcher(
      isAnyOf(
        uploadRagFile.fulfilled,
        uploadRagFile.rejected,
        deleteRagFile.fulfilled,
        deleteRagFile.rejected,
        getRagFiles.fulfilled,
        getRagFiles.rejected
      ),
      (state) => ({
        ...state,
        loading: false,
      })
    );
});
