// Redux
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
// Typings
import { RootState } from "store";
import { StateProps, SummaryConversationMessage } from "./types";
// Schemas
import { SummaryConversationSchema } from "./schemas";
// Initial State
import { initialState } from "./initial-state";
// Main Api
import { getMainApi } from "store/utils/main-api";
// Zod
import { WebSocketConnectionConfigKeys } from "store/middleware/websockets/types";
import { z } from "zod";

/**
 * Fetch Study by Id
 * Example of an async action / thunk
 * @example await/void dispatch(getStudy({ mainApi, studyId }}));
 */
export const getSummaryConversations = createAsyncThunk<
  Partial<StateProps>,
  { summaryId: string },
  { state: RootState }
>("summary-conversations/get", async ({ summaryId }, { getState }) => {
  let data: StateProps["data"] = getState().summaryConversations.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch({
    schema: z.object({
      status: z.literal(200),
      body: z.array(SummaryConversationSchema),
    }),
    method: "GET",
    path: `/summaries/${summaryId}/followUp`,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = result.response.body;
  }
  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

export const addSummaryConversation = createAction(
  "summary-conversations/add",
  function prepare(message: string) {
    return {
      payload: {
        message,
      },
    };
  }
);

export const CONNECT_SUMMARY_CONVERSATIONS_WS_ACTION_TYPE = "summary-conversations/connect-ws";

/**
 * Update history as side effect of study update
 * Example of an async action / thunk
 * @example await/void dispatch(updateHistorySideEffect(id, description));
 */
export const connectSummaryConversationsWS = createAction(
  CONNECT_SUMMARY_CONVERSATIONS_WS_ACTION_TYPE,
  function prepare(summaryId: string, followUpQuestion: string, path?: string) {
    return {
      payload: {
        key: `${WebSocketConnectionConfigKeys.SummaryConversation}-${summaryId}`,
        resourceId: summaryId,
        queryParams: `message=${followUpQuestion}`,
        path
      },
    };
  }
);

export const DISCONNECT_SUMMARY_CONVERSATIONS_WS_ACTION_TYPE =
  "summary-conversations/disconnect-ws";

export const disconnectSummaryConversationsWS = createAction(
  DISCONNECT_SUMMARY_CONVERSATIONS_WS_ACTION_TYPE,
  function prepare(summaryId: string) {
    return {
      payload: {
        key: `${WebSocketConnectionConfigKeys.SummaryConversation}-${summaryId}`,
        resourceId: summaryId,
      },
    };
  }
);
/**
 * Update history as side effect of study update
 * Example of an async action / thunk
 * @example await/void dispatch(updateHistorySideEffect(id, description));
 */
export const streamSummaryConversations = createAction<{
  clientSideId: string;
  message: SummaryConversationMessage;
}>("summary-conversations/stream");

/**
 * Reset User Interview Conversations
 * @example dispatch(resetUserInterviewConversations());
 */
export const resetSummaryConversations = createAction("summary-conversations/reset");
