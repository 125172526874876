// Redux
import { createReducer, isAnyOf } from "@reduxjs/toolkit";
// Types
import { StateProps, SummaryConversation } from "./types";
// State
import {
  addSummaryConversation,
  connectSummaryConversationsWS,
  disconnectSummaryConversationsWS,
  getSummaryConversations,
  resetSummaryConversations,
  streamSummaryConversations,
} from "./actions";
import { initialState } from "./initial-state";
// Actions

export const reducer = createReducer<StateProps>(initialState, (builder) => {
  builder.addCase(resetSummaryConversations, () => initialState);
  builder.addCase(connectSummaryConversationsWS, (state) => state);
  builder.addCase(addSummaryConversation, (state, action) => {
    const { message } = action.payload;

    const newQuestion: SummaryConversation = {
      id: "tempId",
      message,
      created_at: new Date(),
      type: "llm",
    };

    return {
      ...state,
      data: [...(state.data ? state.data : []), newQuestion],
    };
  });
  builder.addCase(disconnectSummaryConversationsWS, (state) => state);
  builder.addCase(streamSummaryConversations, (state, action) => {
    const { clientSideId, message } = action.payload;

    const prevConversation = state?.data?.find((c) => c.id === clientSideId);

    const newConversation: SummaryConversation = {
      id: clientSideId,
      message: prevConversation ? `${prevConversation.message}${message.message}` : message.message,
      created_at: new Date(),
      type: "llm",
    };

    return {
      ...state,
      data: prevConversation
        ? state.data?.map((c) =>
            c.id === clientSideId
              ? {
                  ...c,
                  message: `${c.message}${message.message}`,
                }
              : c
          )
        : [...(state.data ? state.data : []), newConversation],
    };
  });
  builder
    // Loading start
    .addMatcher(isAnyOf(getSummaryConversations.pending), (state) => ({
      ...state,
      loading: true,
    }))
    // Get + Add + Duplicate + Update + Delete Problems fulfilled
    .addMatcher(isAnyOf(getSummaryConversations.fulfilled), (state, action) => ({
      ...state,
      ...action.payload,
    }))
    // Loading end
    .addMatcher(
      isAnyOf(getSummaryConversations.fulfilled, getSummaryConversations.rejected),
      (state) => ({
        ...state,
        loading: false,
      })
    );
});
