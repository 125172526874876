import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { UploadRagFile, StateProps, RagList } from "./types";
import { RootState } from "store";
import { initialState } from "./initial-state";
import { getMainApi } from "store/utils/main-api";
import { z } from "zod";
import { RagListSchema, UploadRagFileSchema } from "./schemas";
import { RcFile } from "antd/es/upload";
import { TransportFailure } from "logic/internals/transports/transported-data/transport-failures";

export const getRagFiles = createAsyncThunk<
  Partial<StateProps>,
  { projectId: string },
  { state: RootState }
>("rag/fetch", async ({ projectId }) => {
  let data: StateProps["data"] = initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: RagList[] }>>({
    schema: z.object({
      status: z.literal(200),
      body: z.array(RagListSchema),
    }),
    skipParsing: false,
    method: "GET",
    path: `/upload/projects/${projectId}`,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = result.response.body;
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

export const uploadRagFile = createAsyncThunk<
  Partial<StateProps>,
  {
    projectId: string;
    file: RcFile;
    onSuccess: () => void;
    onError: () => void;
  },
  { state: RootState }
>("rag/upload", async ({ projectId, file, onError, onSuccess }, { dispatch }) => {
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: UploadRagFile }>>({
    schema: z.object({
      status: z.literal(200),
      body: UploadRagFileSchema,
    }),
    skipParsing: false,
    method: "POST",
    path: `/upload`,
    body: {
      project_id: projectId,
      filename: file.name,
    },
  });

  if (result.failure) {
    error = result.failure;
  } else {
    const { url, fields } = result.response.body;

    const formData = new FormData();
    Object.entries(fields).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append("file", file);

    const upload = await fetch(url, {
      method: "POST",
      body: formData,
    });

    if (upload.ok) {
      onSuccess();
      dispatch(getRagFiles({ projectId }));
    } else {
      onError();
      error = "Failed to upload file" as TransportFailure;
    }
  }

  return {
    error,
  };
});

export const deleteRagFile = createAsyncThunk<
  Partial<StateProps>,
  { projectId: string; fileId: string; onSuccess: () => void; onError: () => void },
  { state: RootState }
>("rag/delete", async ({ projectId, fileId, onSuccess, onError }, { dispatch }) => {
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200 }>>({
    schema: z.object({
      status: z.literal(200),
    }),
    skipParsing: false,
    method: "DELETE",
    path: `/upload/${fileId}`,
  });

  if (result.failure) {
    error = result.failure;
    onError();
  } else {
    onSuccess();
    dispatch(getRagFiles({ projectId }));
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    error,
  };
});

/**
 * Reset Solutions
 * @example dispatch(resetSsoProvider());
 */
export const resetRag = createAction("rag/reset");
