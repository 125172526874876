import { store } from "store";
import { resetAudiences } from "store/modules/audiences/actions/index";
import { resetCustomScript } from "store/modules/custom-script/actions";
import { resetProblems } from "store/modules/problems/actions";
import { resetResearchGoal } from "store/modules/research-goal/actions";
import { resetSolutions } from "store/modules/solutions/actions/index";
import { resetStudyVisualization } from "store/modules/study-visualization/actions";
import { resetStudy } from "store/modules/study/actions";
import { resetSummary } from "store/modules/summaries/actions";
import { resetSyntheticUsers } from "store/modules/synthetic-users/actions";
import { resetUserInterviewConversations } from "store/modules/user-interview-conversations/actions";
import { resetUserInterviews } from "store/modules/user-interviews/actions";

export const resetAllData = () => {
  store.dispatch(resetProblems());
  store.dispatch(resetSolutions());
  store.dispatch(resetAudiences());
  store.dispatch(resetResearchGoal());
  store.dispatch(resetCustomScript());
  store.dispatch(resetSyntheticUsers());
  store.dispatch(resetUserInterviews());
  store.dispatch(resetUserInterviewConversations());
  store.dispatch(resetSummary());
  store.dispatch(resetStudy());
  store.dispatch(resetStudyVisualization());
};
