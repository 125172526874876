// Redux
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
// Typings
import { RootState } from "store";
import { Problem, StateProps } from "../types";
// Schemas
import { ProblemsSchema } from "../schemas";
// Initial State
import { initialState } from "../initial-state";
// Main Api
// Zod
import { getMainApi } from "store/utils/main-api";
import { z } from "zod";

/**
 * Fetch Problems by project Id
 * Example of an async action / thunk
 * @example await/void dispatch(getProblems({ mainApi, studyId }}));
 */
export const getProblems = createAsyncThunk<
  Partial<StateProps>,
  { projectId: string },
  { state: RootState }
>("problems/fetch", async ({ projectId }, { getState }) => {
  let data: StateProps["data"] = getState().problems.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: Problem[] }>>({
    schema: z.object({
      status: z.literal(200),
      body: z.array(ProblemsSchema),
    }),
    skipParsing: false,
    method: "GET",
    path: `/projects/${projectId}/problems`,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      ...data,
      problems: result.response.body,
    };
  }
  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Add Problem
 * Example of an async action / thunk
 * @example await/void dispatch(addProblem({ mainApi, projectId, description }}));
 */
export const addProblem = createAsyncThunk<
  Partial<StateProps>,
  {
    projectId: string;
    description: string;
  },
  { state: RootState }
>("problems/add", async ({ projectId, description }, { getState }) => {
  let data: StateProps["data"] = getState().problems.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: Problem }>>({
    schema: z.object({
      status: z.literal(200),
      body: ProblemsSchema,
    }),
    skipParsing: false,
    method: "POST",
    path: `/problems`,
    body: {
      projectId,
      description,
    },
  });

  if (result.failure) {
    error = result.failure;
  } else {
    const { selectedProblemsIds } = data;
    data = {
      ...data,
      problems: data.problems ? [...data.problems, result.response.body] : [result.response.body],
      selectedProblemsIds: [
        ...(selectedProblemsIds ? selectedProblemsIds : []),
        result.response.body.id,
      ],
    };
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Infer Problems by audience
 * Example of an async action / thunk
 * @example await/void dispatch(inferProblemsByAudience({ mainApi, audienceId }}));
 */
export const inferProblemsByAudience = createAsyncThunk<
  Partial<StateProps>,
  {
    audienceId: string;
  },
  { state: RootState }
>("problems/infer-by-audience", async ({ audienceId }, { getState }) => {
  let data: StateProps["data"] = getState().problems.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: Problem[] }>>({
    schema: z.object({
      status: z.literal(200),
      body: z.array(ProblemsSchema),
    }),
    skipParsing: false,
    method: "POST",
    path: `/problems/infer/${audienceId}`,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      ...data,
      problems: data.problems ? [...data.problems, ...result.response.body] : result.response.body,
    };
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Update Problem
 * Example of an async action / thunk
 * @example await/void dispatch(updateProblem({ mainApi, studyId, description }}));
 */
export const updateProblem = createAsyncThunk<
  Partial<StateProps>,
  {
    projectId: string;
    problemId: string;
    description: string;
  },
  { state: RootState }
>("problems/update", async ({ projectId, problemId, description }, { getState }) => {
  let data: StateProps["data"] = getState().problems.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: Problem }>>({
    schema: z.object({
      status: z.literal(200),
      body: ProblemsSchema,
    }),
    skipParsing: false,
    method: "PUT",
    path: `/problems/${problemId}`,
    body: {
      projectId,
      description,
    },
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      ...data,
      problems: data.problems?.map((problem) =>
        problem.id === problemId ? result.response.body : problem
      ),
      selectedProblemsIds: data.selectedProblemsIds?.map((id) =>
        id === problemId ? result.response.body.id : id
      ),
    };
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Delete Problem
 * Example of an async action / thunk
 * @example await/void dispatch(deleteProblem({ mainApi, problemId }}));
 */
export const deleteProblem = createAsyncThunk<
  Partial<StateProps>,
  {
    problemId: string;
  },
  { state: RootState }
>("problems/delete", async ({ problemId }, { getState }) => {
  let data: StateProps["data"] = getState().problems.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200 }>>({
    schema: z.object({
      status: z.literal(200),
    }),
    skipParsing: false,
    method: "DELETE",
    path: `/problems/${problemId}`,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      ...data,
      problems: data.problems ? data.problems.filter((problem) => problem.id !== problemId) : [],
      selectedProblemsIds: data.selectedProblemsIds?.filter((id) => id !== problemId),
    };
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Delete All Problems
 * Example of an async action / thunk
 * @example await/void dispatch(deleteAllProblem({}}));
 */
export const deleteAllProblems = createAsyncThunk<Partial<StateProps>, void, { state: RootState }>(
  "problems/delete-all",
  async (_, { getState }) => {
    let data: StateProps["data"] = getState().problems.data || initialState.data;
    let error: StateProps["error"] = initialState.error;

    const mainApi = getMainApi();

    // TODO: ask BE for a bulk delete endpoint
    const deletePromises = data.problems?.map(async (problem) => {
      return mainApi.fetch<z.ZodType<{ status: 200 }>>({
        schema: z.object({
          status: z.literal(200),
        }),
        skipParsing: false,
        method: "DELETE",
        path: `/problems/${problem.id}`,
      });
    });

    const deleteResults = deletePromises && (await Promise.all(deletePromises));

    const allPromisesSuccessful = deleteResults && deleteResults.every((result) => !result.failure);

    if (allPromisesSuccessful) {
      data = {
        ...data,
        problems: [],
        selectedProblemsIds: [],
      };
    } else {
      error = deleteResults && deleteResults.find((result) => result.failure)?.failure;
    }

    // The value we return becomes the `fulfilled` action payload
    return {
      data,
      error,
    };
  }
);

/**
 * Toggle Selected Problems
 * @example dispatch(toggleSelectedProblems(problemId));
 */
export const toggleSelectedProblems = createAction<string>("problems/toggle-selected");

/**
 * Set Selected Problems
 * @example dispatch(toggleSelectedProblems(problemId));
 */
export const setSelectedProblems = createAction<string[]>("problems/set-selected");

/**
 * Reset Selected Problems
 * @example dispatch(resetSelectedProblems());
 */
export const resetSelectedProblems = createAction("problems/reset-selected");

/**
 * Reset Problems
 * @example dispatch(resetProblems());
 */
export const resetProblems = createAction("problems/reset");
