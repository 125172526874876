// Redux toolkit
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
// Typings
import { RootState } from "store";
import { ResearchGoal, StateProps } from "./types";
// Schemas
import { ResearchGoalSchema } from "./schemas";
// Initial State
import { initialState } from "./initial-state";
// Main Api
// Zod
import { getMainApi } from "store/utils/main-api";
import { z } from "zod";

/**
 * Fetch ResearchGoal by project Id
 * Example of an async action / thunk
 * @example await/void dispatch(geHistory(projectId));
 */
export const addResearchGoal = createAsyncThunk<
  Partial<StateProps>,
  { projectId: string; description: string },
  { state: RootState }
>("research-goal/add", async ({ projectId, description }) => {
  let data: StateProps["data"] = initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: ResearchGoal }>>({
    schema: z.object({
      status: z.literal(200),
      body: ResearchGoalSchema,
    }),
    skipParsing: false,
    method: "POST",
    path: `/researchGoals`,
    body: {
      projectId,
      description,
    },
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = result.response.body;
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Reset Problems
 * @example dispatch(resetProblems());
 */
export const resetResearchGoal = createAction("research-goal/reset");
