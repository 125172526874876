// Redux
import { createReducer, isAnyOf } from "@reduxjs/toolkit";
// Types
import { StateProps } from "./types";
// State
import { initialState } from "./initial-state";
// Actions
import {
  generateSummary,
  connectSummaryWS,
  disconnectSummaryWS,
  streamSummary,
  resetSummary,
} from "./actions";
import { updateSummarySideEffect } from "../study/actions.side-effects";
import { processSummaryMessage } from "./utils/process-summary-message";

export const reducer = createReducer<StateProps>(initialState, (builder) => {
  builder.addCase(resetSummary, () => initialState);
  builder.addCase(updateSummarySideEffect, (state, action) => ({
    ...state,
    data: action.payload.summary ? action.payload.summary : undefined,
  }));
  builder.addCase(connectSummaryWS, (state) => ({
    ...state,
    loading: true,
  }));
  builder.addCase(disconnectSummaryWS, (state) => ({
    ...state,
    loading: false,
  }));
  builder.addCase(streamSummary, (state, action) => {
    if (!state.data) return state;

    const messages = action.payload;
    const initialSummary = (state.data[0] && state.data[0].summary) || "";

    let summary = Array.isArray(initialSummary) ? initialSummary : [initialSummary];

    let currentSectionIndex = !summary.length ? 0 : summary.length - 1;

    messages.forEach((message) => {
      const { summedSummaryText } = processSummaryMessage(message, summary, currentSectionIndex);
      summary = summedSummaryText;
      currentSectionIndex = !summedSummaryText.length ? 0 : summedSummaryText.length - 1;
    });

    if (state.data[0]) {
      state.data[0].summary = summary;
    }
  });
  builder
    // Loading start
    .addMatcher(isAnyOf(generateSummary.pending), (state) => ({
      ...state,
      loading: true,
    }))
    // Get + Add + Duplicate + Update + Delete Problems fulfilled
    .addMatcher(isAnyOf(generateSummary.fulfilled), (state, action) => ({
      ...state,
      ...action.payload,
    }))
    // Loading end
    .addMatcher(isAnyOf(generateSummary.rejected), (state) => ({
      ...state,
      loading: false,
    }));
});
