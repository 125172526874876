import { z } from "zod";

export const ResearchAssistantSchema = z.object({
  id: z.string(),
  createdAt: z.string(),
});

export const ResearchAssistantResponseSchema = z.object({
  audience: z.union([z.string(), z.array(z.string())]).optional(),
  problems: z.union([z.string(), z.array(z.string())]).optional(),
  solution: z.union([z.string(), z.array(z.string())]).optional(),
  research_goal: z.string().optional(),
  custom_script: z.string().optional(),
  next_question: z.string().optional(),
  status: z.string().optional(),
});
