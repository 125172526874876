// Redux
import { createReducer, isAnyOf } from "@reduxjs/toolkit";
// Types
import { StateProps } from "./types";
// State
import { initialState } from "./initial-state";
// Actions
import {
  addSolution,
  deleteSolution,
  deleteAllSolutions,
  getSolutions,
  resetSolutions,
  resetSelectedSolution,
  setSelectedSolution,
  updateSolution,
} from "./actions/index";
import { addSolutionNew, updateSolutionNew } from "./actions/new-ui";
import { LoadingStatus } from "store/types";
import { updateSolutionsSideEffect } from "../study/actions.side-effects";
import { updateSolutionsProjectSideEffect } from "./actions/side-effects";

export const reducer = createReducer<StateProps>(initialState, (builder) => {
  // Reset solutions
  builder.addCase(resetSolutions, () => initialState);
  builder.addCase(updateSolutionsProjectSideEffect, (state, action) => ({
    ...state,
    error: action.payload.error,
    data: {
      solutions: action.payload.solutions,
      selectedSolutionId: [],
      selectedSolutions: [],
    },
  }));
  // Reset selected solution
  builder.addCase(updateSolutionsSideEffect, (state, action) => ({
    ...state,
    data: {
      solutions: action.payload.solution ? [action.payload.solution] : [],
      selectedSolutionId: action.payload.solution ? [action.payload.solution.id] : [],
      selectedSolutions: action.payload.solution ? [action.payload.solution] : [],
    },
  }));
  // Reset selected solution
  builder.addCase(resetSelectedSolution, (state) => ({
    ...state,
    data: {
      ...state.data,
      selectedSolutionId: [],
      selectedSolutions: [],
    },
  }));
  // Set selected solution
  builder.addCase(setSelectedSolution, (state, action) => ({
    ...state,
    data: {
      ...state.data,
      selectedSolutionId: [action.payload],
    },
  }));
  builder
    // Loading start
    .addMatcher(isAnyOf(getSolutions.pending, deleteAllSolutions.pending), (state) => ({
      ...state,
      loading: LoadingStatus.multiple,
    }));
  builder
    // Loading start
    .addMatcher(
      isAnyOf(
        addSolutionNew.pending,
        updateSolutionNew.pending,
        addSolution.pending,
        updateSolution.pending,
        deleteSolution.pending
      ),
      (state) => ({
        ...state,
        loading: LoadingStatus.single,
      })
    )
    // Get + Add + Duplicate + Update + Delete Solutions fulfilled
    .addMatcher(
      isAnyOf(
        addSolutionNew.fulfilled,
        updateSolutionNew.fulfilled,
        getSolutions.fulfilled,
        addSolution.fulfilled,
        updateSolution.fulfilled,
        deleteSolution.fulfilled,
        deleteAllSolutions.fulfilled
      ),
      (state, action) => ({
        ...state,
        ...action.payload,
      })
    )
    // Loading end
    .addMatcher(
      isAnyOf(
        addSolutionNew.fulfilled,
        addSolutionNew.rejected,
        updateSolutionNew.fulfilled,
        updateSolutionNew.rejected,
        getSolutions.fulfilled,
        getSolutions.rejected,
        addSolution.fulfilled,
        addSolution.rejected,
        updateSolution.fulfilled,
        updateSolution.rejected,
        deleteSolution.fulfilled,
        deleteSolution.rejected,
        deleteAllSolutions.fulfilled,
        deleteAllSolutions.rejected
      ),
      (state) => ({
        ...state,
        loading: LoadingStatus.none,
      })
    );
});
