// Redux
import { createReducer, isAnyOf } from "@reduxjs/toolkit";
// Types
import { StateProps } from "./types";
// State
import { initialState } from "./initial-state";
// Actions
import { getHistory, resetHistory } from "./actions";
// Side Effects
import {
  deleteStudyHistorySideEffect,
  updateStudyHistorySideEffect,
} from "store/modules/study/actions.side-effects";

export const reducer = createReducer<StateProps>(initialState, (builder) => {
  // Side Effects
  builder.addCase(resetHistory, () => initialState);
  builder.addCase(updateStudyHistorySideEffect, (state, action) => {
    if (state.data) {
      const { id, description } = action.payload;
      const study = state.data.find((study) => study.id === id);
      if (study) {
        study.description = description;
      }
    }
  });
  builder.addCase(deleteStudyHistorySideEffect, (state, action) => ({
    ...state,
    data: state.data?.filter((study) => study.id !== action.payload.id),
  }));
  builder
    // Loading start
    .addMatcher(isAnyOf(getHistory.pending), (state) => ({
      ...state,
      loading: true,
    }))
    // Get History fulfilled
    .addMatcher(isAnyOf(getHistory.fulfilled), (state, action) => ({
      ...state,
      ...action.payload,
    }))
    // Loading end
    .addMatcher(isAnyOf(getHistory.fulfilled, getHistory.rejected), (state) => ({
      ...state,
      loading: false,
    }));
});
