import { UnknownAction } from "@reduxjs/toolkit";
import { WebSocketConnectionConfig } from "store/middleware/websockets/types";
import { disconnectSummaryConversationsWS, getSummaryConversations } from "../actions";
import { SummaryConversationMessageSchema } from "../schemas";

export const onClose: WebSocketConnectionConfig<
  typeof SummaryConversationMessageSchema
>["onClose"] = (store, resourceId, wasClosed) => {
  if (wasClosed && wasClosed.by === "error" && store && resourceId) {
    store.dispatch(disconnectSummaryConversationsWS(resourceId));
  }

  if (wasClosed && wasClosed.by === "server" && store && resourceId) {
    // run fetch for user interview conversations
    // TODO: check typings
    store.dispatch(
      getSummaryConversations({
        summaryId: resourceId,
      }) as unknown as UnknownAction
    ); // Update type of dispatch

    store.dispatch(disconnectSummaryConversationsWS(resourceId));
  }
};
