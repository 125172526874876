// Redux
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
// Typings
import { RootState } from "store";
import { Audience, StateProps } from "../types";
// Schemas
import { AudiencesSchema } from "../schemas";
// Initial State
import { initialState } from "../initial-state";
// Main Api
// Zod
import { getMainApi } from "store/utils/main-api";
import { z } from "zod";

/**
 * Fetch Audiences by project Id
 * Example of an async action / thunk
 * @example await/void dispatch(getAudiences({ mainApi, studyId }}));
 */
export const getAudiences = createAsyncThunk<
  Partial<StateProps>,
  { projectId: string },
  { state: RootState }
>("audiences/fetch", async ({ projectId }, { getState }) => {
  let data: StateProps["data"] = getState().audiences.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: Audience[] }>>({
    schema: z.object({
      status: z.literal(200),
      body: z.array(AudiencesSchema),
    }),
    skipParsing: false,
    method: "GET",
    //TODO: Change path to /audiences when the endpoint is ready
    path: `/projects/${projectId}/syntheticUsers`,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      ...data,
      audiences: result.response.body,
    };
  }
  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Add Audience
 * Example of an async action / thunk
 * @example await/void dispatch(addAudience({ mainApi, projectId, description }}));
 */
export const addAudience = createAsyncThunk<
  Partial<StateProps>,
  {
    projectId: string;
    description: string;
    onSuccess?: (audience: Audience) => void;
  },
  { state: RootState }
>("audiences/add", async ({ projectId, description, onSuccess }, { getState }) => {
  let data: StateProps["data"] = getState().audiences.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: Audience }>>({
    schema: z.object({
      status: z.literal(200),
      body: AudiencesSchema,
    }),
    skipParsing: false,
    method: "POST",
    path: `/syntheticUsers`,
    body: {
      projectId,
      description,
    },
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      ...data,
      audiences: data.audiences
        ? [...data.audiences, result.response.body]
        : [result.response.body],
      selectedAudiencesIds: [...(data.selectedAudiencesIds || []), result.response.body.id],
    };

    onSuccess && onSuccess(result.response.body);
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * // TODO: DO WE NEED THIS?
 * Infer Audiences by audienceId
 * Example of an async action / thunk
 * @example await/void dispatch(inferAudience({ mainApi, audienceId }}));
 */
export const inferAudience = createAsyncThunk<
  Partial<StateProps>,
  {
    audienceId: string;
  },
  { state: RootState }
>("audiences/infer", async ({ audienceId }, { getState }) => {
  let data: StateProps["data"] = getState().audiences.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: Audience[] }>>({
    schema: z.object({
      status: z.literal(200),
      body: z.array(AudiencesSchema),
    }),
    skipParsing: false,
    method: "POST",
    path: `/syntheticUsers/infer/${audienceId}`,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      ...data,
      audiences: data.audiences
        ? [...data.audiences, ...result.response.body]
        : result.response.body,
    };
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Infer Audiences by problem
 * Example of an async action / thunk
 * @example await/void dispatch(inferProblemsByAudience({ mainApi, problemId }}));
 */
export const inferAudiencesByProblem = createAsyncThunk<
  Partial<StateProps>,
  {
    problemId: string;
  },
  { state: RootState }
>("audiences/infer-by-problem", async ({ problemId }, { getState }) => {
  let data: StateProps["data"] = getState().audiences.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: Audience[] }>>({
    schema: z.object({
      status: z.literal(200),
      body: z.array(AudiencesSchema),
    }),
    skipParsing: false,
    method: "POST",
    // TODO: check with backend if the path should be changed
    path: `/syntheticUsers/infer_by_problem/${problemId}`,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      ...data,
      audiences: data.audiences
        ? [...data.audiences, ...result.response.body]
        : result.response.body,
    };
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Update Audience
 * Example of an async action / thunk
 * @example await/void dispatch(updateAudience({ mainApi, studyId, description }}));
 */
export const updateAudience = createAsyncThunk<
  Partial<StateProps>,
  {
    projectId: string;
    audienceId: string;
    description: string;
  },
  { state: RootState }
>("audiences/update", async ({ projectId, audienceId, description }, { getState }) => {
  let data: StateProps["data"] = getState().audiences.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: Audience }>>({
    schema: z.object({
      status: z.literal(200),
      body: AudiencesSchema,
    }),
    skipParsing: false,
    method: "PUT",
    path: `/syntheticUsers/${audienceId}`,
    body: {
      projectId,
      description,
    },
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      ...data,
      audiences: data.audiences?.map((audience) =>
        audience.id === audienceId ? result.response.body : audience
      ),
      selectedAudiencesIds: data.selectedAudiencesIds?.map((id) =>
        id === audienceId ? result.response.body.id : id
      ),
    };
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Delete Audience
 * Example of an async action / thunk
 * @example await/void dispatch(deleteAudience({ mainApi, problemId }}));
 */
export const deleteAudience = createAsyncThunk<
  Partial<StateProps>,
  {
    audienceId: string;
  },
  { state: RootState }
>("audiences/delete", async ({ audienceId }, { getState }) => {
  let data: StateProps["data"] = getState().audiences.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200 }>>({
    schema: z.object({
      status: z.literal(200),
    }),
    skipParsing: false,
    method: "DELETE",
    path: `/syntheticUsers/${audienceId}`,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      ...data,
      audiences: data.audiences
        ? data.audiences?.filter((problem) => problem.id !== audienceId)
        : [],
      selectedAudiencesIds: data.selectedAudiencesIds?.filter((id) => id !== audienceId),
    };
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Delete All Audiences
 * Example of an async action / thunk
 * @example await/void dispatch(deleteAllAudiences({}}));
 */
export const deleteAllAudiences = createAsyncThunk<Partial<StateProps>, void, { state: RootState }>(
  "audiences/delete-all",
  async (_, { getState }) => {
    let data: StateProps["data"] = getState().audiences.data || initialState.data;
    let error: StateProps["error"] = initialState.error;

    const mainApi = getMainApi();

    // Ask BE for delete all audiences endpoint
    const deletePromises = data.audiences?.map(async (audience) => {
      return mainApi.fetch<z.ZodType<{ status: 200 }>>({
        schema: z.object({
          status: z.literal(200),
        }),
        skipParsing: false,
        method: "DELETE",
        path: `/syntheticUsers/${audience.id}`,
      });
    });

    const deleteResults = deletePromises && (await Promise.all(deletePromises));

    const allPromisesSuccessful = deleteResults && deleteResults.every((result) => !result.failure);

    if (allPromisesSuccessful) {
      data = {
        ...data,
        audiences: [],
        selectedAudiencesIds: [],
      };
    } else {
      error = deleteResults && deleteResults.find((result) => result.failure)?.failure;
    }

    // The value we return becomes the `fulfilled` action payload
    return {
      data,
      error,
    };
  }
);

/**
 * Toggle Selected Audiences
 * @example dispatch(toggleSelectedProblems(problemId));
 */
export const toggleSelectedAudiences = createAction<string>("audiences/toggle-selected");

/**
 * Set Selected Problems
 * @example dispatch(toggleSelectedProblems(problemId));
 */
export const setSelectedAudience = createAction<string>("audiences/set-selected");

/**
 * Reset Selected Audiences
 * @example dispatch(resetSelectedProblems(problemId));
 */
export const resetSelectedAudiences = createAction("audiences/reset-selected");

/**
 * Reset Audiences
 * @example dispatch(resetAudiences());
 */
export const resetAudiences = createAction("audiences/reset");
