import { WebSocketConnectionConfig } from "store/middleware/websockets/types";
import { onClose } from "./on-close";
import { onInvalidMessageReceived } from "./on-invalid-message-recieved";
import { onMessage } from "./on-message";
import { userInterviewMessageSchema } from "../schemas";

export const userInterviewsWSConnectionConfig: WebSocketConnectionConfig<
  typeof userInterviewMessageSchema
> = {
  path: "/userInterviews/stream/",
  messageSchema: userInterviewMessageSchema,
  onMessage: onMessage,
  onClose: onClose,
  onInvalidMessageReceived: onInvalidMessageReceived,
};
