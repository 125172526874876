// Redux
import { createReducer, isAnyOf } from "@reduxjs/toolkit";
// Types
import { StateProps, UserInterviewConversation } from "./types";
// State
import { initialState } from "./initial-state";
// Actions
import {
  addUserInterviewConversation,
  connectUserInterviewConversationsWS,
  disconnectUserInterviewConversationsWS,
  streamUserInterviewsConversations,
  getUserInterviewConversations,
  resetUserInterviewConversations,
} from "./actions";

export const reducer = createReducer<StateProps>(initialState, (builder) => {
  builder.addCase(resetUserInterviewConversations, () => initialState);
  builder.addCase(connectUserInterviewConversationsWS, (state) => state);
  builder.addCase(addUserInterviewConversation, (state, action) => {
    const { userInterviewId, message } = action.payload;

    const newQuestion: UserInterviewConversation = {
      id: "tempId",
      userInterviewId,
      message,
      createdAt: new Date(),
      type: "llm",
    };

    return {
      ...state,
      data: [...(state.data ? state.data : []), newQuestion],
    };
  });
  builder.addCase(disconnectUserInterviewConversationsWS, (state) => state);
  builder.addCase(streamUserInterviewsConversations, (state, action) => {
    const { clientSideId, message } = action.payload;

    const prevConversation = state?.data?.find((c) => c.id === clientSideId);

    const newConversation: UserInterviewConversation = {
      id: clientSideId,
      userInterviewId: clientSideId,
      message: prevConversation ? `${prevConversation.message}${message.message}` : message.message,
      createdAt: new Date(),
      type: "llm",
    };

    return {
      ...state,
      data: prevConversation
        ? state.data?.map((c) =>
            c.id === clientSideId
              ? {
                  ...c,
                  message: `${c.message}${message.message}`,
                }
              : c
          )
        : [...(state.data ? state.data : []), newConversation],
    };
  });
  builder
    // Loading start
    .addMatcher(isAnyOf(getUserInterviewConversations.pending), (state) => ({
      ...state,
      loading: true,
    }))
    // Get + Add + Duplicate + Update + Delete Problems fulfilled
    .addMatcher(isAnyOf(getUserInterviewConversations.fulfilled), (state, action) => ({
      ...state,
      ...action.payload,
    }))
    // Loading end
    .addMatcher(
      isAnyOf(getUserInterviewConversations.fulfilled, getUserInterviewConversations.rejected),
      (state) => ({
        ...state,
        loading: false,
      })
    );
});
