import { TransportFailure } from "logic/internals/transports/transported-data/transport-failures";
import { z } from "zod";
import { ProjectMemberSchema, ProjectSchema, ProjectsListSchema } from "./schemas";

export type Project = z.infer<typeof ProjectSchema>;
export type ProjectsList = z.infer<typeof ProjectsListSchema>;
export type ProjectMember = z.infer<typeof ProjectMemberSchema>;

export enum ProjectSettings {
  ProblemExplorationMode = "problem_exploration",
  SolutionFeedbackMode = "solution_feedback",
  // TODO: update when implemented
  MessageTestingMode = "message_testing",
  CustomScriptMode = "custom_script",
  DynamicInterviewMode = "dynamic_interview",
  EthnographicResearchMode = "ethnographic_research"
}

export enum ProjectInviteStatus {
  Accepted = "accepted",
  Pending = "pending",
  Rejected = "rejected",
}

export enum ProjectMemberRole {
  Admin = "admin",
  Editor = "editor",
}

export enum ProjectMemberStatus {
  Active = "active",
  Pending = "pending",
  Inactive = "inactive",
}

export type StateProps = {
  loading: number;
  error?: TransportFailure;
  /*
    the idea of loaded data is represented by the truthy value of data,
    hence the need for a string signaling that past interviews were indeed requested
    but there were no past interviews
  */
  data: {
    projectsList?: Project[];
    projectMembers?: ProjectMember[];
    project?: Project;
    projectSettings?: ProjectSettings;
  };
};
