import { z } from "zod";

export const UploadRagFileSchema = z.object({
  url: z.string(),
  fields: z.record(z.string(), z.string()),
});

export const RagListSchema = z.object({
  id: z.string(),
  filename: z.string(),
  projectId: z.string(),
  uploadedBy: z.string(),
  uploadedAt: z.string(),
});
