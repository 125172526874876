import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Audience } from "../audiences/types";
import { Problem } from "../problems/types";
import { Solution } from "../solutions/types";
import { ResearchGoal } from "../research-goal/types";
import { GeneratedSyntheticUser } from "../synthetic-users/types";
import { UserInterview } from "../user-interviews/types";
import { Summary } from "../summaries/types";
import { StateProps, Study } from "./types";
import { RootState } from "store";
import { initialState } from "./initial-state";
import { getMainApi } from "store/utils/main-api";
import { z } from "zod";
import { StudySchema } from "./schemas";
import { ProjectSettings } from "../projects/types";
import { setProjectSettings } from "../projects/actions";
import { resetStudyVisualization } from "../study-visualization/actions";

/**
 * Update history as side effect of study update
 * Example of an async action / thunk
 * @example await/void dispatch(updateStudyHistorySideEffect({ id, description }));
 */
export const updateStudyHistorySideEffect = createAction<{ id: string; description: string }>(
  "study/update-study-history-side-effect"
);

/**
 * Update history as side effect of study deletion
 * Example of an async action / thunk
 * @example await/void dispatch(deleteStudyHistorySideEffect({ id }));
 */
export const deleteStudyHistorySideEffect = createAction<{ id: string }>(
  "study/delete-study-history-side-effect"
);

/**
 * Update history as side effect of study update
 * Example of an async action / thunk
 * @example await/void dispatch(updateHistorySideEffect(id, description));
 */
export const updateAudiencesSideEffect = createAction<{ audiences: Audience[] }>(
  "study/update-audiences"
);

/**
 * Update history as side effect of study update
 * Example of an async action / thunk
 * @example await/void dispatch(updateHistorySideEffect(id, description));
 */
export const updateProblemsSideEffect = createAction<{ problems: Problem[] }>(
  "study/update-problems"
);

/**
 * Update history as side effect of study update
 * Example of an async action / thunk
 * @example await/void dispatch(updateHistorySideEffect(id, description));
 */
export const updateSolutionsSideEffect = createAction<{ solution: Solution | null | undefined }>(
  "study/update-solutions"
);

/**
 * Update history as side effect of study update
 * Example of an async action / thunk
 * @example await/void dispatch(updateHistorySideEffect(id, description));
 */
export const updateResearchGoalSideEffect = createAction<{
  researchGoal: ResearchGoal | null | undefined;
}>("study/update-research-goal");

/**
 * Update history as side effect of study update
 * Example of an async action / thunk
 * @example await/void dispatch(updateHistorySideEffect(id, description));
 */
export const updateCustomScriptSideEffect = createAction<{
  customScript: string | undefined;
  questionsNumber: number | undefined;
}>("study/update-custom-script");

/**
 * Update history as side effect of study update
 * Example of an async action / thunk
 * @example await/void dispatch(updateHistorySideEffect(id, description));
 */
export const updateSyntheticUsersSideEffect = createAction<{
  syntheticUsers: GeneratedSyntheticUser[] | null | undefined;
}>("study/update-synthetic-users");

/**
 * Update history as side effect of study update
 * Example of an async action / thunk
 * @example await/void dispatch(updateHistorySideEffect(id, description));
 */
export const updateUserInterviewsSideEffect = createAction<{ userInterviews: UserInterview[] }>(
  "study/update-user-interviews"
);

/**
 * Update history as side effect of study update
 * Example of an async action / thunk
 * @example await/void dispatch(updateHistorySideEffect(id, description));
 */
export const updateSummarySideEffect = createAction<{ summary: Summary[] | null | undefined }>(
  "study/update-summary"
);

function getProjectSettingsCode(key: string): ProjectSettings {
  switch (key) {
    case "Problem Exploration":
      return ProjectSettings.ProblemExplorationMode;
    case "Dynamic Interview":
      return ProjectSettings.DynamicInterviewMode;
    case "Custom Script":
      return ProjectSettings.CustomScriptMode;
    case "Solution Feedback":
      return ProjectSettings.SolutionFeedbackMode;
    default:
      return ProjectSettings.ProblemExplorationMode;
  }
}
/**
 * Fetch Study by Id
 * Example of an async action / thunk
 * @example await/void dispatch(getStudy({ mainApi, studyId }}));
 */
export const populateStudy = createAsyncThunk<
  Partial<StateProps>,
  { studyId: string },
  { state: RootState }
>("study/populate", async ({ studyId }, { dispatch }) => {
  let data: StateProps["data"] = initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: Study }>>({
    schema: z.object({
      status: z.literal(200),
      body: StudySchema,
    }),
    skipParsing: false,
    method: "GET",
    path: `/studies/${studyId}`,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = result.response.body;
    // TODO: discuss types with BE
    const {
      audiences,
      problems,
      solution,
      researchGoal,
      generatedSyntheticUsers: syntheticUsers,
      userInterviews,
      summaries,
      studyStrategy,
    } = result.response.body;

    const customScript =
      studyStrategy === "Custom Script" ? userInterviews[0]?.topics?.join("\n") : undefined;

    const questionsNumber =
      studyStrategy === "Custom Script" ? userInterviews[0]?.topics?.length : undefined;

    dispatch(updateAudiencesSideEffect({ audiences }));
    dispatch(updateProblemsSideEffect({ problems }));
    dispatch(updateSolutionsSideEffect({ solution }));
    dispatch(updateResearchGoalSideEffect({ researchGoal }));
    dispatch(updateCustomScriptSideEffect({ customScript, questionsNumber }));
    dispatch(updateSyntheticUsersSideEffect({ syntheticUsers }));
    dispatch(updateUserInterviewsSideEffect({ userInterviews }));
    dispatch(updateSummarySideEffect({ summary: summaries }));
    dispatch(resetStudyVisualization());
    dispatch(setProjectSettings(getProjectSettingsCode(studyStrategy)));
  }
  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});
