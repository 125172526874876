// Redux
import { createReducer, isAnyOf } from "@reduxjs/toolkit";
// Types
import { StateProps } from "./types";
// State
import { initialState } from "./initial-state";
// Actions
import {
  addWorkspace,
  deleteWorkspace,
  getLatestWorkspace,
  getWorkspaceById,
  getWorkspacesList,
  updateWorkspaceDescription,
  acceptWorkspaceInvite,
  rejectWorkspaceInvite,
  removeWorkspaceInvite,
  getWorkspaceMembers,
  removeWorkspaceMember,
  grantWorkspaceMemberOwnerStatus,
  revokeWorkspaceMemberOwnerStatus,
  resetWorkspace,
  resetWorkspaceSettings,
  setWorkspaceMode,
  getWorkspaceUsageDashboard,
} from "./actions";

export const reducer = createReducer<StateProps>(initialState, (builder) => {
  builder.addCase(resetWorkspace, (state) => ({
    error: initialState.error,
    data: initialState.data,
    loading: state.loading,
  }));
  builder.addCase(resetWorkspaceSettings, (state) => ({
    ...state,
    data: {
      ...state.data,
      projectSettings: undefined,
    },
  }));
  builder.addCase(setWorkspaceMode, (state, action) => ({
    ...state,
    data: {
      ...state.data,
      workspaceMode: action.payload,
    },
  }));
  // Set project setti
  builder
    // Loading start
    .addMatcher(
      isAnyOf(
        getLatestWorkspace.pending,
        getWorkspaceById.pending,
        getWorkspacesList.pending,
        updateWorkspaceDescription.pending,
        addWorkspace.pending,
        deleteWorkspace.pending,
        acceptWorkspaceInvite.pending,
        rejectWorkspaceInvite.pending,
        removeWorkspaceInvite.pending,
        getWorkspaceMembers.pending,
        removeWorkspaceMember.pending,
        grantWorkspaceMemberOwnerStatus.pending,
        revokeWorkspaceMemberOwnerStatus.pending,
        getWorkspaceUsageDashboard.pending
      ),
      (state) => ({
        ...state,
        loading: state.loading + 1,
      })
    )
    // Actions that only return errors
    .addMatcher(
      isAnyOf(
        acceptWorkspaceInvite.fulfilled,
        rejectWorkspaceInvite.fulfilled,
        getWorkspaceUsageDashboard.fulfilled
      ),
      (state, action) => ({
        ...state,
        ...action.payload,
      })
    )
    // Actions that return partial data and errors
    .addMatcher(
      isAnyOf(
        getWorkspacesList.fulfilled,
        getLatestWorkspace.fulfilled,
        getWorkspaceById.fulfilled,
        updateWorkspaceDescription.fulfilled,
        addWorkspace.fulfilled,
        deleteWorkspace.fulfilled,
        getWorkspaceMembers.fulfilled,
        removeWorkspaceInvite.fulfilled,
        removeWorkspaceMember.fulfilled,
        grantWorkspaceMemberOwnerStatus.fulfilled,
        revokeWorkspaceMemberOwnerStatus.fulfilled
      ),
      (state, action) => ({
        ...state,
        error: action.payload.error,
        data: {
          ...state.data,
          ...action.payload.data,
        },
      })
    )

    // Loading end
    .addMatcher(
      isAnyOf(
        getLatestWorkspace.fulfilled,
        getLatestWorkspace.rejected,
        getWorkspaceById.fulfilled,
        getWorkspaceById.rejected,
        getWorkspacesList.fulfilled,
        getWorkspacesList.rejected,
        updateWorkspaceDescription.fulfilled,
        updateWorkspaceDescription.rejected,
        addWorkspace.fulfilled,
        addWorkspace.rejected,
        deleteWorkspace.fulfilled,
        deleteWorkspace.rejected,
        acceptWorkspaceInvite.fulfilled,
        acceptWorkspaceInvite.rejected,
        rejectWorkspaceInvite.fulfilled,
        rejectWorkspaceInvite.rejected,
        removeWorkspaceInvite.fulfilled,
        removeWorkspaceInvite.rejected,
        getWorkspaceMembers.fulfilled,
        getWorkspaceMembers.rejected,
        removeWorkspaceMember.fulfilled,
        removeWorkspaceMember.rejected,
        grantWorkspaceMemberOwnerStatus.fulfilled,
        grantWorkspaceMemberOwnerStatus.rejected,
        revokeWorkspaceMemberOwnerStatus.fulfilled,
        revokeWorkspaceMemberOwnerStatus.rejected,
        getWorkspaceUsageDashboard.fulfilled,
        getWorkspaceUsageDashboard.rejected
      ),
      (state) => ({
        ...state,
        loading: state.loading - 1,
      })
    );
});
