import { store } from "store";
import { disconnectSummaryWS, resetSummary } from "store/modules/summaries/actions";
import { resetSyntheticUsers } from "store/modules/synthetic-users/actions";
import { resetUserInterviewConversations } from "store/modules/user-interview-conversations/actions";
import {
  disconnectUserInterviewsWS,
  resetUserInterviews,
} from "store/modules/user-interviews/actions";

export const resetInsightsData = () => {
  const { syntheticUsers, userInterviews, userInterviewConversations, summary } = store.getState();
  if (syntheticUsers.data) {
    store.dispatch(resetSyntheticUsers());
  }
  if (userInterviews.data) {
    store.dispatch(resetUserInterviews());
    Object.keys(userInterviews.data).forEach((key) => {
      store.dispatch(disconnectUserInterviewsWS(key, { by: "client" }));
    });
  }
  if (userInterviewConversations.data) {
    store.dispatch(resetUserInterviewConversations());
  }
  if (summary.data && summary.data[0]) {
    store.dispatch(resetSummary());
    store.dispatch(disconnectSummaryWS(summary.data[0].summaryId, { by: "client" }));
  }
};
