// Redux
import { createAsyncThunk } from "@reduxjs/toolkit";
// Typings
import { RootState } from "store";
import { Problem, StateProps } from "../types";
// Schemas
import { ProblemsSchema } from "../schemas";
// Initial State
import { initialState } from "../initial-state";
// Main Api
// Zod
import { getMainApi } from "store/utils/main-api";
import { z } from "zod";
import { TransportFailure } from "logic/internals/transports/transported-data/transport-failures";

/**
 * Add Problem
 * Example of an async action / thunk
 * @example await/void dispatch(addProblem({ mainApi, projectId, description }}));
 */
export const addProblemsNew = createAsyncThunk<
  Partial<StateProps>,
  {
    projectId: string;
    descriptions: string[];
  },
  { state: RootState }
>("problems/add-new", async ({ projectId, descriptions }, { getState }) => {
  let data: StateProps["data"] = getState().problems.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const problems: Problem[] = [];
  const errors: TransportFailure[] = [];

  await Promise.all(
    descriptions?.map(async (description) => {
      const response = await mainApi.fetch<z.ZodType<{ status: 200; body: Problem }>>({
        schema: z.object({
          status: z.literal(200),
          body: ProblemsSchema,
        }),
        skipParsing: false,
        method: "POST",
        path: `/problems`,
        body: {
          projectId,
          description,
        },
      });

      if (response.failure) {
        errors.push(response.failure);
      } else {
        problems.push(response.response.body);
      }
    })
  );

  if (errors[0]) {
    error = errors[0];
  } else {
    data = {
      ...data,
      problems: data.problems ? [...data.problems, ...problems] : problems,
      selectedProblems: data.selectedProblems ? [...data.selectedProblems, ...problems] : problems,
      selectedProblemsIds: problems.map((problem) => problem.id),
    };
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Update Problem
 * Example of an async action / thunk
 * @example await/void dispatch(updateProblem({ mainApi, studyId, description }}));
 */
export const updateProblemNew = createAsyncThunk<
  Partial<StateProps>,
  {
    projectId: string;
    problemId: string;
    description: string;
  },
  { state: RootState }
>("problems/update-new", async ({ projectId, problemId, description }, { getState }) => {
  let data: StateProps["data"] = getState().problems.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: Problem }>>({
    schema: z.object({
      status: z.literal(200),
      body: ProblemsSchema,
    }),
    skipParsing: false,
    method: "PUT",
    path: `/problems/${problemId}`,
    body: {
      projectId,
      description,
    },
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      ...data,
      selectedProblems: data.selectedProblems?.map((problem) =>
        problem.id === problemId ? result.response.body : problem
      ),
    };
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});
