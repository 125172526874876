// Redux
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
// Typings
import { RootState } from "store";
import { Solution, StateProps } from "../types";
// Schemas
import { SolutionSchema } from "../schemas";
// Initial State
import { initialState } from "../initial-state";
// Main Api
// Zod
import { getMainApi } from "store/utils/main-api";
import { z } from "zod";

/**
 * Fetch Solutions by project Id
 * Example of an async action / thunk
 * @example await/void dispatch(getSolutions({ mainApi, studyId }}));
 */
export const getSolutions = createAsyncThunk<
  Partial<StateProps>,
  { projectId: string },
  { state: RootState }
>("solutions/fetch", async ({ projectId }, { getState }) => {
  let data: StateProps["data"] = getState().solutions.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: Solution[] }>>({
    schema: z.object({
      status: z.literal(200),
      body: z.array(SolutionSchema),
    }),
    skipParsing: false,
    method: "GET",
    // TODO: ask BE to fix endpoint
    path: `/project/${projectId}/solutions`,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      ...data,
      solutions: result.response.body,
    };
  }
  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Add Solution
 * Example of an async action / thunk
 * @example await/void dispatch(addSolution({ mainApi, projectId, description }}));
 */
export const addSolution = createAsyncThunk<
  Partial<StateProps>,
  {
    projectId: string;
    description: string;
  },
  { state: RootState }
>("solutions/add", async ({ projectId, description }, { getState }) => {
  let data: StateProps["data"] = getState().solutions.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: Solution }>>({
    schema: z.object({
      status: z.literal(200),
      body: SolutionSchema,
    }),
    skipParsing: false,
    method: "POST",
    path: `/solutions`,
    body: {
      projectId,
      description,
    },
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      ...data,
      solutions: data.solutions
        ? [...data.solutions, result.response.body]
        : [result.response.body],
    };
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Update Solution
 * Example of an async action / thunk
 * @example await/void dispatch(updateSolution({ mainApi, studyId, description }}));
 */
export const updateSolution = createAsyncThunk<
  Partial<StateProps>,
  {
    projectId: string;
    solutionId: string;
    description: string;
  },
  { state: RootState }
>("solutions/update", async ({ projectId, solutionId, description }, { getState }) => {
  let data: StateProps["data"] = getState().solutions.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: Solution }>>({
    schema: z.object({
      status: z.literal(200),
      body: SolutionSchema,
    }),
    skipParsing: false,
    method: "PUT",
    path: `/solutions/${solutionId}`,
    body: {
      projectId,
      description,
    },
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      ...data,
      solutions: data.solutions?.map((solution) =>
        solution.id === solutionId ? result.response.body : solution
      ),
      selectedSolutionId: [result.response.body.id],
    };
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Delete Solution
 * Example of an async action / thunk
 * @example await/void dispatch(deleteSolution({ mainApi, solutionId }}));
 */
export const deleteSolution = createAsyncThunk<
  Partial<StateProps>,
  {
    solutionId: string;
  },
  { state: RootState }
>("solutions/delete", async ({ solutionId }, { getState }) => {
  let data: StateProps["data"] = getState().solutions.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200 }>>({
    schema: z.object({
      status: z.literal(200),
    }),
    skipParsing: false,
    method: "DELETE",
    path: `/solutions/${solutionId}`,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      ...data,
      solutions: data.solutions
        ? data.solutions.filter((solution) => solution.id !== solutionId)
        : [],
      selectedSolutionId: data.selectedSolutionId?.filter((id) => id !== solutionId),
    };
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Delete All Solutions
 * Example of an async action / thunk
 * @example await/void dispatch(deleteAllSolutions({}}));
 */
export const deleteAllSolutions = createAsyncThunk<Partial<StateProps>, void, { state: RootState }>(
  "solutions/delete-all",
  async (_, { getState }) => {
    let data: StateProps["data"] = getState().solutions.data || initialState.data;
    let error: StateProps["error"] = initialState.error;

    const mainApi = getMainApi();

    // Ask BE for bulk delete endpoint
    const deletePromises = data.solutions?.map(async (solution) => {
      return mainApi.fetch<z.ZodType<{ status: 200 }>>({
        schema: z.object({
          status: z.literal(200),
        }),
        skipParsing: false,
        method: "DELETE",
        path: `/solutions/${solution.id}`,
      });
    });

    const deleteResults = deletePromises && (await Promise.all(deletePromises));

    const allPromisesSuccessful = deleteResults && deleteResults.every((result) => !result.failure);

    if (allPromisesSuccessful) {
      data = {
        ...data,
        solutions: [],
        selectedSolutionId: [],
      };
    } else {
      error = deleteResults && deleteResults.find((result) => result.failure)?.failure;
    }

    // The value we return becomes the `fulfilled` action payload
    return {
      data,
      error,
    };
  }
);

/**
 * Set Selected Solution
 * @example dispatch(setSelectedSolution(solutionId));
 */
export const setSelectedSolution = createAction<string>("solutions/set-selected");

/**
 * Reset Selected Solution
 * @example dispatch(resetSelectedSolution(solutionId));
 */
export const resetSelectedSolution = createAction("solutions/reset-selected");

/**
 * Reset Solutions
 * @example dispatch(resetSolutions());
 */
export const resetSolutions = createAction("solutions/reset");
