import { WebSocketConnectionConfig } from "store/middleware/websockets/types";
import { disconnectSummaryWS } from "../actions";
import { SummaryMessageSchema } from "../schemas";

export const onInvalidMessageReceived: WebSocketConnectionConfig<
  typeof SummaryMessageSchema
>["onInvalidMessageReceived"] = (store, resource) => {
  if (resource) {
    store.dispatch(disconnectSummaryWS(resource));
  }
};
