import { RootState } from "store";
import { StateProps, ProjectMember } from "../types";
import { z } from "zod";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getMainApi } from "store/utils/main-api";
import { ProjectMemberSchema, ProjectSchema } from "../schemas";
import { initialState } from "../initial-state";
import { TransportFailure } from "logic/internals/transports/transported-data/transport-failures";

/**
 * Delete Workspace
 * Example of an async action / thunk
 * @example await/void dispatch(deleteProject({ mainApi, projectId }));
 */
export const getProjectMembers = createAsyncThunk<
  { data: StateProps["data"]; error: StateProps["error"] },
  { projectId: string },
  { state: RootState }
>("projects/get-members", async ({ projectId }, { getState }) => {
  let data: StateProps["data"] = getState().projects.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: ProjectMember[] }>>({
    schema: z.object({
      status: z.literal(200),
      body: z.array(ProjectMemberSchema),
    }),
    method: "GET",
    path: `/projects/project/${projectId}/users`,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      projectMembers: result.response.body,
    };
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Delete project
 * Example of an async action / thunk
 * @example await/void dispatch(deleteproject({ mainApi, projectId }));
 */
export const addProjectMember = createAsyncThunk<
  Partial<StateProps>,
  {
    projectId: string;
    userId: string;
    onSuccess: () => void;
    onError: (error: TransportFailure) => void;
  },
  { state: RootState }
>("projects/add-member", async ({ projectId, userId, onSuccess, onError }, { getState }) => {
  let data: StateProps["data"] = getState().projects.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch({
    schema: z.object({
      status: z.union([
        z.literal(200),
        z.literal(401),
        z.literal(403),
        z.literal(404),
        z.literal(409),
        z.literal(500),
      ]),
      body: z.union([z.array(ProjectMemberSchema), z.object({ detail: z.string() })]),
    }),
    method: "POST",
    path: `/projects/project/${projectId}/users/${userId}`,
  });

  if (result.failure) {
    error = result.failure;
    onError(error);
  } else {
    if (result.response.status === 500) {
      error = TransportFailure.InternalServerError;
    }

    if ("detail" in result.response.body) {
      switch (result.response.status) {
        case 401: {
          if (result.response.body.detail === TransportFailure.UserUnautorized) {
            error = TransportFailure.UserUnautorized;
          }
          break;
        }
        case 403: {
          if (result.response.body.detail === TransportFailure.WorkspaceSeatsLimitReached) {
            error = TransportFailure.WorkspaceSeatsLimitReached;
          }
          break;
        }
        case 404: {
          if (result.response.body.detail === TransportFailure.WorkspaceNotFound) {
            error = TransportFailure.WorkspaceNotFound;
          }
          break;
        }
        case 409: {
          if (result.response.body.detail === "member_already_part_of_workspace") {
            error = TransportFailure.MemberAlreadyPartOfWorkspace;
          }
          if (result.response.body.detail === TransportFailure.MembersAlreadyInvited) {
            error = TransportFailure.MembersAlreadyInvited;
          }
          break;
        }
      }
    }

    if (error) {
      onError(error);
    } else {
      const parsedResult = z.array(ProjectMemberSchema).safeParse(result.response.body);
      if (parsedResult.success) {
        data = {
          projectMembers: parsedResult.data,
        };
        onSuccess();
      }
    }
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Delete project
 * Example of an async action / thunk
 * @example await/void dispatch(deleteproject({ mainApi, projectId }));
 */
export const inviteProjectMember = createAsyncThunk<
  Partial<StateProps>,
  {
    projectId: string;
    email: string;
    onSuccess: () => void;
    onError: (error: TransportFailure) => void;
  },
  { state: RootState }
>("projects/invite-member", async ({ projectId, email, onSuccess, onError }, { dispatch }) => {
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch({
    schema: z.object({
      status: z.union([
        z.literal(200),
        z.literal(401),
        z.literal(403),
        z.literal(404),
        z.literal(409),
        z.literal(500),
      ]),
      body: z.union([ProjectSchema, z.object({ detail: z.string() })]),
    }),
    method: "POST",
    path: `/projectInvites/invite`,
    body: {
      projectId,
      email,
    },
  });

  if (result.failure) {
    error = result.failure;
    onError(error);
  } else {
    if (result.response.status === 500) {
      error = TransportFailure.InternalServerError;
    }

    if ("detail" in result.response.body) {
      switch (result.response.status) {
        case 401: {
          if (result.response.body.detail === TransportFailure.UserUnautorized) {
            error = TransportFailure.UserUnautorized;
          }
          break;
        }
        case 403: {
          if (result.response.body.detail === TransportFailure.WorkspaceSeatsLimitReached) {
            error = TransportFailure.WorkspaceSeatsLimitReached;
          }
          break;
        }
        case 404: {
          if (result.response.body.detail === TransportFailure.WorkspaceNotFound) {
            error = TransportFailure.WorkspaceNotFound;
          }
          break;
        }
        case 409: {
          if (result.response.body.detail === TransportFailure.MemberAlreadyPartOfWorkspace) {
            error = TransportFailure.MemberAlreadyPartOfWorkspace;
          }
          if (result.response.body.detail === TransportFailure.MembersAlreadyInvited) {
            error = TransportFailure.MembersAlreadyInvited;
          }
          break;
        }
      }
    }

    if (error) {
      onError(error);
    } else {
      onSuccess();
      dispatch(getProjectMembers({ projectId }));
    }
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    error,
  };
});

/**
 * Delete Workspace
 * Example of an async action / thunk
 * @example await/void dispatch(deleteProject({ mainApi, projectId }));
 */
export const removeProjectMember = createAsyncThunk<
  {
    data: StateProps["data"];
    error: StateProps["error"];
  },
  { projectId: string; userId: string; onSuccess: () => void },
  { state: RootState }
>("projects/remove-member", async ({ projectId, userId, onSuccess }, { getState }) => {
  let data: StateProps["data"] = getState().projects.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch({
    schema: z.object({
      status: z.union([z.literal(200), z.literal(401), z.literal(404), z.literal(500)]),
      body: z.array(ProjectMemberSchema),
    }),
    method: "DELETE",
    path: `/projects/project/${projectId}/users/${userId}`,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      projectMembers: result.response.body,
    };

    onSuccess();
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Delete Project
 * Example of an async action / thunk
 * @example await/void dispatch(deleteProject({ mainApi, projectId }));
 */
export const removeProjectInvite = createAsyncThunk<
  { data: StateProps["data"]; error: StateProps["error"] },
  { projectInviteId: string; onSuccess: () => void },
  { state: RootState }
>("workspaces/remove-invite", async ({ projectInviteId, onSuccess }, { getState }) => {
  let data: StateProps["data"] = getState().projects.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch({
    schema: z.object({
      status: z.union([z.literal(200), z.literal(401), z.literal(404), z.literal(500)]),
      body: z.array(ProjectMemberSchema),
    }),
    method: "DELETE",
    path: `/projectInvites/${projectInviteId}`,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      projectMembers: result.response.body,
    };

    onSuccess();
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

export const grantProjectMemberAdminStatus = createAsyncThunk<
{
  data: StateProps["data"];
  error: StateProps["error"];
},
{ projectId: string; userId: string; },
{ state: RootState }
>("projects/grant-admin-status", async ({ projectId, userId }, { getState }) => {
  let data: StateProps["data"] = getState().projects.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch({
    schema: z.object({
      status: z.union([z.literal(200), z.literal(401), z.literal(404), z.literal(500)]),
      body: z.array(ProjectMemberSchema),
    }),
    method: "POST",
    path: `/projects/project/${projectId}/users/${userId}/admin`,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      projectMembers: result.response.body,
    };
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

export const revokeProjectMemberAdminStatus = createAsyncThunk<
{
  data: StateProps["data"];
  error: StateProps["error"];
},
{ projectId: string; userId: string; },
{ state: RootState }
>("projects/revoke-admin-status", async ({ projectId, userId }, { getState }) => {
  let data: StateProps["data"] = getState().projects.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch({
    schema: z.object({
      status: z.union([z.literal(200), z.literal(401), z.literal(404), z.literal(500)]),
      body: z.array(ProjectMemberSchema),
    }),
    method: "POST",
    path: `/projects/project/${projectId}/users/${userId}/revoke-admin`,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      projectMembers: result.response.body,
    };
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});
