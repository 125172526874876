import { WebSocketConnectionConfig } from "store/middleware/websockets/types";
import { disconnectUserInterviewConversationsWS } from "../actions";
import { UserInterviewConversationMessageSchema } from "../schemas";

export const onInvalidMessageReceived: WebSocketConnectionConfig<
  typeof UserInterviewConversationMessageSchema
>["onInvalidMessageReceived"] = (store, resourceId) => {
  if (resourceId) {
    store.dispatch(disconnectUserInterviewConversationsWS(resourceId));
  }
};
