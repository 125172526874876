import { z } from "zod";

export enum StudyVisualizationStatus {
  FAILED = "failed",
  RUNNING = "running",
  DONE = "done",
}

export const StudyVisualizationPreloadSchema = z.object({
  status: z.nativeEnum(StudyVisualizationStatus),
  message: z.string(),
  col_slices: z.array(z.string()).optional().nullable(),
});

export const ConceptsMatchesSchema = z.object({
  id: z.string(),
  study_id: z.string(),
  concept: z.string(),
  criteria: z.string(),
  summary: z.string().optional().nullable(),
  rep_examples: z.array(z.string()),
  prevalence: z.number(),
  n_matches: z.number(),
  highlights: z.string().nullable(),
  text_col: z.string(),
  full_name: z.string(),
  text_bullets: z.array(z.string()),
  quotes: z.array(z.string()),
  concept_score: z.number(),
  score_rationale: z.string().optional().nullable(),
});

export const StudyVisualizationSchema = z.object({
  matrix: z.array(
    z.object({
      id: z.string(),
      value: z.number(),
      example: z.string(),
      score: z.number(),
      concept: z.string(),
      n: z.number(),
    })
  ),
  concept_detail: z.object({
    items: z.record(z.string(), z.object({ slice_size: z.string() })),
    concepts: z.record(
      z.string(),
      z.object({
        criteria: z.string().nullable(),
        summary: z.string().nullable(),
        concept_matches: z.string().nullable(),
        representative_examples: z.string().nullable(),
      })
    ),
  }),
  concepts_matched: z.array(ConceptsMatchesSchema),
  concepts_non_matched: z.array(ConceptsMatchesSchema),
});
