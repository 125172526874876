import { WebSocketConnectionConfig } from "store/middleware/websockets/types";
import { SummaryConversationMessageSchema } from "../schemas";
import { onClose } from "./on-close";
import { onInvalidMessageReceived } from "./on-invalid-message-received";
import { onMessage } from "./on-message";

export const summariesConversationsWSConnectionConfig: WebSocketConnectionConfig<
  typeof SummaryConversationMessageSchema
> = {
  path: "/summaries/followUp/stream/",
  messageSchema: SummaryConversationMessageSchema,
  onMessage: onMessage,
  onClose: onClose,
  onInvalidMessageReceived: onInvalidMessageReceived,
};
