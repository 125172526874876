import { useMemo } from "react";
import { z } from "zod";
import { Logger } from "../../logging/logger";
import { EnvironmentVariables } from "../../runtime/environment-variables";
import {
  TransportFailure,
  getTransportFailureFromString,
} from "../../transports/transported-data/transport-failures";
import { getFirebaseAuth } from "../firebase/firebase-auth";

let forceRefreshToken = true;

export interface MainApi {
  fetch<ZodSchema extends z.ZodType<{ status: number; body?: unknown }>>(args: {
    /*
      Describes the response types that are accepted by the application,
      like:

      z.object({
        status: 200,
        body: z.object({
            prop: z.string()
          })
      })
    */
    schema: ZodSchema;
    path: string;
    body?: unknown;
    method: "HEAD" | "GET" | "DELETE" | "POST" | "PATCH" | "PUT";
    skipParsing?: boolean;
    queryParams?: Record<string, string>;
    isFileDownload?: boolean;
  }): Promise<
    | {
        failure: TransportFailure;
      }
    | {
        failure?: undefined;
        response: z.TypeOf<ZodSchema>;
      }
  >;
}

export function useMainApi(): MainApi {
  /*
    - authenticates requests
    - validates if server responses match the expected schemas,
      in order to guarantee type-safety and detect server-side bugs immediately
    - logs errors
    - if the error was already predicted, return the corresponding TransportFailure enum value
  */
  return useMemo(() => {
    return {
      fetch: async (args) => {
        const headers: { [key: string]: string } = {};

        const firebaseUser = getFirebaseAuth().currentUser;

        if (firebaseUser) {
          let token: string;

          try {
            /*
              After email verification, the JWT in the client still says that the user is not verified,
              resulting in a 502 error in our backend. 

              To solve this issue, we force reload the token during the first request to the backend
            */
            token = await firebaseUser.getIdToken(forceRefreshToken);
            forceRefreshToken = false;
          } catch (_err) {
            const error = _err as { [key: string]: unknown };

            if (error.code === "auth/network-request-failed") {
              return {
                failure: TransportFailure.ConnectionFailure,
              };
            } else {
              throw new Error();
            }
          }

          headers["Authorization"] = `Bearer ${token}`;
        }

        let response: Response;
        // Add query parameters to the URL
        const queryParamsString = args.queryParams
          ? `?${new URLSearchParams(args.queryParams).toString()}`
          : "";

        try {
          response = await fetch(
            `${EnvironmentVariables.MAIN_API_URL}${args.path}${queryParamsString}`,
            {
              method: args.method,
              body: ["POST", "PATCH", "PUT"].includes(args.method)
                ? JSON.stringify(args.body)
                : undefined,
              headers: {
                "Content-Type": "application/json",
                ...headers,
              },
            }
          );
        } catch (error) {
          Logger.logError("use-main-api:fetch:cors-or-connection-failure", new Error(), {
            request: {
              path: args.path,
              method: args.method,
              body: args.body,
            },
          });
          return {
            failure: TransportFailure.ConnectionFailure,
          };
        }

        if (args.isFileDownload) {
          if (response.status === 200) {
            return {
              response: {
                status: response.status,
                body: await response.blob(),
              },
            };
          } else {
            Logger.logError("use-main-api:fetch:unexpected-response", new Error(), {
              request: {
                path: args.path,
                method: args.method,
                body: args.body,
              },
              response: {
                status: response.status,
              },
            });

            return {
              failure: TransportFailure.UnexpectedResponse,
            };
          }
        } else {
          const responseText = await response.text();
          let responseJSON: unknown;

          if (args.skipParsing) {
            responseJSON = responseText;
          } else {
            responseJSON = responseText ? (JSON.parse(responseText) as unknown) : undefined;
          }

          const validationResult = args.schema.safeParse({
            status: response.status,
            body: responseJSON,
          });

          if (validationResult.success) {
            return {
              response: validationResult.data,
            };
          } else {
            if (response.status === 404) {
              return {
                failure: TransportFailure.NotFound,
              };
            } else if (response.status === 401) {
              // TODO logout and return TransportFailure.AbortedAndDealtWith

              throw new Error("Not implemented");
            } else if (response.status === 403) {
              return {
                failure: getTransportFailureFromString(responseText, TransportFailure.Forbidden),
              };
            } else {
              Logger.logError("use-main-api:fetch:unexpected-response", new Error(), {
                request: {
                  path: args.path,
                  method: args.method,
                  body: args.body,
                },
                response: {
                  status: response.status,
                  validationErrors: validationResult.error.format(),
                },
              });

              return {
                failure: TransportFailure.UnexpectedResponse,
              };
            }
          }
        }
      },
    };
  }, []);
}
