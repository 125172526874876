// Redux
import { createReducer, isAnyOf } from "@reduxjs/toolkit";
// Types
import { StateProps } from "./types";
// State
import { initialState } from "./initial-state";
// Actions
import {
  addAudience,
  deleteAudience,
  deleteAllAudiences,
  getAudiences,
  inferAudience,
  inferAudiencesByProblem,
  resetAudiences,
  resetSelectedAudiences,
  toggleSelectedAudiences,
  updateAudience,
  setSelectedAudience,
} from "./actions/index";
import { addAudienceNew, updateAudienceNew } from "./actions/new-ui";
import { LoadingStatus } from "store/types";
import { updateAudiencesSideEffect } from "../study/actions.side-effects";
import { updateAudiencesProjectSideEffect } from "./actions/side-effects";

export const reducer = createReducer<StateProps>(initialState, (builder) => {
  // Reset audiences
  builder.addCase(resetAudiences, () => initialState);
  builder.addCase(updateAudiencesProjectSideEffect, (state, action) => ({
    ...state,
    error: action.payload.error,
    data: {
      audiences: action.payload?.audiences,
      selectedAudiencesIds: [],
      selectedAudiences: [],
    },
  }));
  // Reset selected problems
  builder.addCase(updateAudiencesSideEffect, (state, action) => ({
    ...state,
    data: {
      audiences: action.payload.audiences,
      selectedAudiencesIds: action.payload.audiences.map((audience) => audience.id),
      selectedAudiences: action.payload.audiences,
    },
  }));
  builder.addCase(setSelectedAudience, (state, action) => ({
    ...state,
    data: {
      ...state.data,
      selectedAudiencesIds: [action.payload],
    },
  }));
  builder.addCase(resetSelectedAudiences, (state) => ({
    ...state,
    data: {
      ...state.data,
      selectedAudiencesIds: [],
      selectedAudiences: [],
    },
  }));
  // Toggle selected problems
  builder.addCase(toggleSelectedAudiences, (state, action) => {
    const isSelected = state.data.selectedAudiencesIds?.includes(action.payload);
    return {
      ...state,
      data: {
        ...state.data,
        selectedAudiencesIds: isSelected
          ? state.data.selectedAudiencesIds?.filter((id) => id !== action.payload)
          : [...(state.data.selectedAudiencesIds || []), action.payload],
      },
    };
  });
  builder
    // Loading start (multiple)
    .addMatcher(
      isAnyOf(
        getAudiences.pending,
        inferAudience.pending,
        inferAudiencesByProblem.pending,
        deleteAllAudiences.pending
      ),
      (state) => ({
        ...state,
        loading: LoadingStatus.multiple,
      })
    );
  builder
    // Loading start (single)
    .addMatcher(
      isAnyOf(
        addAudience.pending,
        updateAudience.pending,
        deleteAudience.pending,
        addAudienceNew.pending,
        updateAudienceNew.pending
      ),
      (state) => ({
        ...state,
        loading: LoadingStatus.single,
      })
    )
    // Get + Add + Duplicate + Update + Delete Problems fulfilled
    .addMatcher(
      isAnyOf(
        getAudiences.fulfilled,
        addAudience.fulfilled,
        addAudienceNew.fulfilled,
        updateAudienceNew.fulfilled,
        inferAudience.fulfilled,
        inferAudiencesByProblem.fulfilled,
        updateAudience.fulfilled,
        deleteAudience.fulfilled,
        deleteAllAudiences.fulfilled
      ),
      (state, action) => ({
        ...state,
        ...action.payload,
      })
    )
    // Loading end
    .addMatcher(
      isAnyOf(
        getAudiences.fulfilled,
        getAudiences.rejected,
        addAudience.fulfilled,
        addAudience.rejected,
        addAudienceNew.fulfilled,
        addAudienceNew.rejected,
        updateAudienceNew.fulfilled,
        updateAudienceNew.rejected,
        inferAudience.fulfilled,
        inferAudience.rejected,
        inferAudiencesByProblem.fulfilled,
        inferAudiencesByProblem.rejected,
        updateAudience.fulfilled,
        updateAudience.rejected,
        deleteAudience.fulfilled,
        deleteAudience.rejected,
        deleteAllAudiences.fulfilled,
        deleteAllAudiences.rejected
      ),
      (state) => ({
        ...state,
        loading: LoadingStatus.none,
      })
    );
});
