// Redux
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
// Typings
import { RootState } from "store";
import { StateProps, UserInterviewConversationMessage } from "./types";
// Schemas
import { UserInterviewConversationSchema } from "./schemas";
// Initial State
import { initialState } from "./initial-state";
// Main Api
import { getMainApi } from "store/utils/main-api";
// Zod
import { WebSocketConnectionConfigKeys } from "store/middleware/websockets/types";
import { z } from "zod";

/**
 * Fetch Study by Id
 * Example of an async action / thunk
 * @example await/void dispatch(getStudy({ mainApi, studyId }}));
 */
export const getUserInterviewConversations = createAsyncThunk<
  Partial<StateProps>,
  { interviewId: string },
  { state: RootState }
>("user-interview-conversations/get", async ({ interviewId }, { getState }) => {
  let data: StateProps["data"] = getState().userInterviewConversations.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch({
    schema: z.object({
      status: z.literal(200),
      body: z.array(UserInterviewConversationSchema),
    }),
    method: "GET",
    path: `/conversations/userInterview/${interviewId}`,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = result.response.body;
  }
  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

export const addUserInterviewConversation = createAction(
  "user-interview-conversations/add",
  function prepare(userInterviewId: string, message: string) {
    return {
      payload: {
        userInterviewId,
        message,
      },
    };
  }
);

export const CONNECT_USER_INTERVIEW_CONVERSATIONS_WS_ACTION_TYPE =
  "user-interview-conversations/connect-ws";

/**
 * Update history as side effect of study update
 * Example of an async action / thunk
 * @example await/void dispatch(updateHistorySideEffect(id, description));
 */
export const connectUserInterviewConversationsWS = createAction(
  CONNECT_USER_INTERVIEW_CONVERSATIONS_WS_ACTION_TYPE,
  function prepare(userInterviewId: string, followUpQuestion: string) {
    return {
      payload: {
        key: `${WebSocketConnectionConfigKeys.UserInterviewConversation}-${userInterviewId}`,
        resourceId: userInterviewId,
        queryParams: `message=${followUpQuestion}`,
      },
    };
  }
);

export const DISCONNECT_USER_INTERVIEWS_CONVERSATIONS_WS_ACTION_TYPE =
  "user-interview-conversations/disconnect-ws";

export const disconnectUserInterviewConversationsWS = createAction(
  DISCONNECT_USER_INTERVIEWS_CONVERSATIONS_WS_ACTION_TYPE,
  function prepare(userInterviewId: string) {
    return {
      payload: {
        key: `${WebSocketConnectionConfigKeys.UserInterviewConversation}-${userInterviewId}`,
        resourceId: userInterviewId,
      },
    };
  }
);
/**
 * Update history as side effect of study update
 * Example of an async action / thunk
 * @example await/void dispatch(updateHistorySideEffect(id, description));
 */
export const streamUserInterviewsConversations = createAction<{
  clientSideId: string;
  message: UserInterviewConversationMessage;
}>("user-interview-conversations/stream");

/**
 * Reset User Interview Conversations
 * @example dispatch(resetUserInterviewConversations());
 */
export const resetUserInterviewConversations = createAction("user-interview-conversations/reset");
