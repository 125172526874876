import { createReducer, isAnyOf } from "@reduxjs/toolkit";

import { StateProps } from "./types";

import { initialState } from "./initial-state";

import { getCurrentUser, resetCurrentUser } from "./actions";

export const reducer = createReducer<StateProps>(initialState, (builder) => {
    builder.addCase(resetCurrentUser, () => initialState);

    builder.addMatcher(
        isAnyOf(
            getCurrentUser.pending
        ),
        (state) => ({
            ...state,
            loading: state.loading + 1,
        })
    )
    .addMatcher(
        isAnyOf(
            getCurrentUser.fulfilled,
        ),
        (state, action) => ({
            ...state,
            error: action.payload.error,
            data: {
                ...state.data,
                ...action.payload.data,
            },
        })
    )
    .addMatcher(
        isAnyOf(
            getCurrentUser.fulfilled,
            getCurrentUser.rejected
        ),
        (state) => ({
            ...state,
            loading: state.loading - 1,
        })
    );
});
