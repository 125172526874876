import { WebSocketConnectionConfig } from "store/middleware/websockets/types";
import { disconnectSummaryWS, handleRemainingBatchedMessages } from "../actions";
import { SummaryMessageSchema } from "../schemas";

export const onClose: WebSocketConnectionConfig<typeof SummaryMessageSchema>["onClose"] = (
  store,
  resourceId
) => {
  if (store && resourceId) {
    store.dispatch(disconnectSummaryWS(resourceId));
    handleRemainingBatchedMessages();
  }
};
