import { createAction } from "@reduxjs/toolkit";
import { Solution } from "../types";
import { TransportFailure } from "logic/internals/transports/transported-data/transport-failures";

/**
 * Update history as side effect of study update
 * Example of an async action / thunk
 * @example await/void dispatch(updateStudyHistorySideEffect({ id, description }));
 */
export const updateSolutionsProjectSideEffect = createAction<{
  solutions?: Solution[];
  error?: TransportFailure;
}>("solutions/update-solutions-project-side-effect");
