// Redux
import { createAsyncThunk } from "@reduxjs/toolkit";
// Typings
import { RootState } from "store";
import { Audience, StateProps } from "../types";
// Schemas
import { AudiencesSchema } from "../schemas";
// Initial State
import { initialState } from "../initial-state";
// Main Api
// Zod
import { getMainApi } from "store/utils/main-api";
import { z } from "zod";

/**
 * Add Audience
 * Example of an async action / thunk
 * @example await/void dispatch(addAudience({ mainApi, projectId, description }}));
 */
export const addAudienceNew = createAsyncThunk<
  Partial<StateProps>,
  {
    projectId: string;
    description: string;
    onSuccess?: (audienceId: string) => void;
  },
  { state: RootState }
>("audiences/add-new", async ({ projectId, description, onSuccess }, { getState }) => {
  let data: StateProps["data"] = getState().audiences.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: Audience }>>({
    schema: z.object({
      status: z.literal(200),
      body: AudiencesSchema,
    }),
    skipParsing: false,
    method: "POST",
    path: `/syntheticUsers`,
    body: {
      projectId,
      description,
    },
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      ...data,
      audiences: [result.response.body, ...(data.audiences || [])],
      selectedAudiences: [...(data.selectedAudiences || []), result.response.body],
      selectedAudiencesIds: [...(data.selectedAudiencesIds || []), result.response.body.id],
    };

    onSuccess && onSuccess(result.response.body.id);
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Update Audience
 * Example of an async action / thunk
 * @example await/void dispatch(updateAudience({ mainApi, studyId, description }}));
 */
export const updateAudienceNew = createAsyncThunk<
  Partial<StateProps>,
  {
    projectId: string;
    audienceId: string;
    description: string;
  },
  { state: RootState }
>("audiences/update-new", async ({ projectId, audienceId, description }, { getState }) => {
  let data: StateProps["data"] = getState().audiences.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: Audience }>>({
    schema: z.object({
      status: z.literal(200),
      body: AudiencesSchema,
    }),
    skipParsing: false,
    method: "PUT",
    path: `/syntheticUsers/${audienceId}`,
    body: {
      projectId,
      description,
    },
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      ...data,
      selectedAudiences: data.selectedAudiences?.map((audience) =>
        audience.id === audienceId ? result.response.body : audience
      ),
      selectedAudiencesIds: data.selectedAudiencesIds?.map((id) =>
        id === audienceId ? result.response.body.id : id
      ),
    };
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});
