import { WebSocketConnectionConfig } from "store/middleware/websockets/types";
import { disconnectUserInterviewsWS, handleUserInterviewMessage } from "../actions";
import { userInterviewMessageSchema } from "../schemas";

export const onMessage: WebSocketConnectionConfig<
  typeof userInterviewMessageSchema
>["onMessage"] = (message, store, resourceId) => {
  if (message.type === "error" && resourceId) {
    store.dispatch(disconnectUserInterviewsWS(resourceId));
  }

  if (resourceId) {
    handleUserInterviewMessage(resourceId, message);
  }
};
