// Redux
import { createReducer, isAnyOf } from "@reduxjs/toolkit";
// Types
import { StateProps } from "./types";
// State
import { initialState } from "./initial-state";
// Actions
import { initAssistant, sendAssistantMessage, resetResearchAssistant } from "./actions";

export const reducer = createReducer<StateProps>(initialState, (builder) => {
  builder.addCase(resetResearchAssistant, () => initialState);
  builder
    // Loading start
    .addMatcher(isAnyOf(sendAssistantMessage.pending), (state) => ({
      ...state,
      loading: true,
    }))
    // Get Study fulfilled
    .addMatcher(
      isAnyOf(initAssistant.fulfilled, sendAssistantMessage.fulfilled),
      (state, action) => ({
        ...state,
        error: action.payload.error,
        data: {
          ...state.data,
          ...action.payload.data,
        },
      })
    )
    // Loading end
    .addMatcher(
      isAnyOf(sendAssistantMessage.fulfilled, sendAssistantMessage.rejected),
      (state) => ({
        ...state,
        loading: false,
      })
    );
});
