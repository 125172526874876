// Redux
import { createReducer, isAnyOf } from "@reduxjs/toolkit";
// Types
import { StateProps } from "./types";
// State
import { initialState } from "./initial-state";
// Actions
import {
  addProblem,
  deleteAllProblems,
  deleteProblem,
  getProblems,
  inferProblemsByAudience,
  resetProblems,
  resetSelectedProblems,
  setSelectedProblems,
  toggleSelectedProblems,
  updateProblem,
} from "./actions/index";
import { addProblemsNew, updateProblemNew } from "./actions/new-ui";
import { LoadingStatus } from "store/types";
import { updateProblemsSideEffect } from "../study/actions.side-effects";
import { updateProblemsProjectSideEffect } from "./actions/side-effects";

export const reducer = createReducer<StateProps>(initialState, (builder) => {
  // Reset problems
  builder.addCase(resetProblems, () => initialState);
  builder.addCase(updateProblemsProjectSideEffect, (state, action) => ({
    ...state,
    error: action.payload.error,
    data: {
      problems: action.payload.problems,
      selectedProblemsIds: [],
      selectedProblems: [],
    },
  }));
  // Reset selected problems
  builder.addCase(updateProblemsSideEffect, (state, action) => ({
    ...state,
    data: {
      problems: action.payload.problems,
      selectedProblemsIds: action.payload.problems.map((problem) => problem.id),
      selectedProblems: action.payload.problems,
    },
  }));
  // Reset selected problems
  builder.addCase(resetSelectedProblems, (state) => ({
    ...state,
    data: {
      ...state.data,
      selectedProblemsIds: [],
      selectedProblems: [],
    },
  }));
  builder.addCase(setSelectedProblems, (state, action) => ({
    ...state,
    data: {
      ...state.data,
      selectedProblemsIds: action.payload,
    },
  }));
  // Toggle selected problems
  builder.addCase(toggleSelectedProblems, (state, action) => {
    const isSelected = state.data.selectedProblemsIds?.includes(action.payload);

    return {
      ...state,
      data: {
        ...state.data,
        selectedProblemsIds: isSelected
          ? state.data.selectedProblemsIds?.filter((id) => id !== action.payload)
          : [...(state.data.selectedProblemsIds || []), action.payload],
      },
    };
  });
  builder
    // Loading start (multiple)
    .addMatcher(
      isAnyOf(getProblems.pending, inferProblemsByAudience.pending, deleteAllProblems.pending),
      (state) => ({
        ...state,
        loading: LoadingStatus.multiple,
      })
    );
  builder
    // Loading start (single)
    .addMatcher(
      isAnyOf(
        addProblem.pending,
        addProblemsNew.pending,
        updateProblemNew.pending,
        updateProblem.pending,
        deleteProblem.pending
      ),
      (state) => ({
        ...state,
        loading: LoadingStatus.single,
      })
    )
    // Get + Add + Duplicate + Update + Delete Problems fulfilled
    .addMatcher(
      isAnyOf(
        getProblems.fulfilled,
        addProblemsNew.fulfilled,
        updateProblemNew.fulfilled,
        addProblem.fulfilled,
        inferProblemsByAudience.fulfilled,
        updateProblem.fulfilled,
        deleteProblem.fulfilled,
        deleteAllProblems.fulfilled
      ),
      (state, action) => ({
        ...state,
        ...action.payload,
      })
    )
    // Loading end
    .addMatcher(
      isAnyOf(
        addProblemsNew.fulfilled,
        addProblemsNew.rejected,
        updateProblemNew.fulfilled,
        updateProblemNew.rejected,
        getProblems.fulfilled,
        getProblems.rejected,
        addProblem.fulfilled,
        addProblem.rejected,
        inferProblemsByAudience.fulfilled,
        inferProblemsByAudience.rejected,
        updateProblem.fulfilled,
        updateProblem.rejected,
        deleteProblem.fulfilled,
        deleteProblem.rejected,
        deleteAllProblems.fulfilled,
        deleteAllProblems.rejected
      ),
      (state) => ({
        ...state,
        loading: LoadingStatus.none,
      })
    );
});
