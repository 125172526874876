import { WebSocketConnectionConfig } from "store/middleware/websockets/types";
import { disconnectSummaryConversationsWS } from "../actions";
import { SummaryConversationMessageSchema } from "../schemas";

export const onInvalidMessageReceived: WebSocketConnectionConfig<
  typeof SummaryConversationMessageSchema
>["onInvalidMessageReceived"] = (store, resourceId) => {
  if (resourceId) {
    store.dispatch(disconnectSummaryConversationsWS(resourceId));
  }
};
