export function getAnalytics() {
  return {
    identify: (args: { userId: string, firebaseId: string; isAnonymous: boolean; email: string | undefined }) => {
      if (window.analytics) {
        analytics.identify(args.userId, {
          firebaseId: args.firebaseId,
          isAnonymous: args.isAnonymous,
          email: args.email
        });
      }
    },
    track: (eventName: string, eventProperties?: { [key: string]: unknown }) => {
      if (window.analytics) {
        analytics.track(eventName, eventProperties);
      }
    },
  };
}
