import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { SsoProvider, StateProps } from "./types";
import { RootState } from "store";
import { initialState } from "./initial-state";
import { getMainApi } from "store/utils/main-api";
import { z } from "zod";
import { SsoProviderSchema } from "./schemas";

// get_sso_provider_domain_by_email
export const getSsoProviderDomainByEmail = createAsyncThunk<
    Partial<StateProps>,
    { email: string },
    { state: RootState }
>("sso-provider/fetch", async ({ email }, { getState }) => {
    let data: StateProps["data"] = getState().ssoProvider.data || initialState.data;
    let error: StateProps["error"] = initialState.error;

    const mainApi = getMainApi();

    const result = await mainApi.fetch<z.ZodType<{ status: 200; body: SsoProvider | undefined | null}>>({
        schema: z.object({
            status: z.literal(200),
            body: SsoProviderSchema.nullable(),
        }),
        skipParsing: false,
        method: "GET",
        path: `/ssoProvider/${email}`,
    });

    if (result.failure) {
        error = result.failure;
    } else {
        data = {
            ...data,
            ssoProvider: result.response.body,
        };
    }

    return {
        data,
        error,
    };
});

/**
 * Reset Solutions
 * @example dispatch(resetSsoProvider());
 */
export const resetSsoProvider = createAction("sso-provider/reset");