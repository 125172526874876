import { UnknownAction } from "@reduxjs/toolkit";
import { WebSocketConnectionConfig } from "store/middleware/websockets/types";
import { disconnectUserInterviewsWS, getUserInterviewAfterStreaming } from "../actions";
import { userInterviewMessageSchema } from "./types";

export const onClose: WebSocketConnectionConfig<typeof userInterviewMessageSchema>["onClose"] = (
  store,
  resourceId,
  wasClosed
) => {
  if (wasClosed && wasClosed.by === "error" && store && resourceId) {
    store.dispatch(disconnectUserInterviewsWS(resourceId));
  }

  if (wasClosed && wasClosed.by === "server" && store && resourceId) {
    // run fetch for user interviews
    // TODO: check typings
    store.dispatch(
      getUserInterviewAfterStreaming({
        interviewId: resourceId,
      }) as unknown as UnknownAction
    ); // Update type of dispatch

    store.dispatch(disconnectUserInterviewsWS(resourceId));
  }
};
