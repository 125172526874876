import { ProjectSettings } from "store/modules/projects/types";

/**
 * Workaround until message testing mode is implemented
 * @param studyStrategy
 * @returns studyStrategy
 */
export const handleStudyStrategy = (studyStrategy?: ProjectSettings) => {
  if (studyStrategy === ProjectSettings.MessageTestingMode)
    return ProjectSettings.SolutionFeedbackMode;
  return studyStrategy;
};
