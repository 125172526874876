import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { initialState } from "./initial-state";
import { StateProps } from "./types";

import { getSsoProviderDomainByEmail, resetSsoProvider } from "./actions";
import { LoadingStatus } from "store/types";

export const reducer = createReducer<StateProps>(initialState, (builder) => {
    // Reset sso provider
    builder.addCase(resetSsoProvider, () => initialState);

    builder
        .addMatcher(
            isAnyOf(getSsoProviderDomainByEmail.pending),
            (state) => ({
                ...state,
                loading: LoadingStatus.single
            })
        )
        .addMatcher(
            isAnyOf(getSsoProviderDomainByEmail.fulfilled),
            (state, action) => ({
                ...state,
                ...action.payload,
            })
        )
        .addMatcher(
            isAnyOf(
                getSsoProviderDomainByEmail.fulfilled,
                getSsoProviderDomainByEmail.rejected,
            ),
            (state) => ({
                ...state,
                loading: LoadingStatus.none,
            })
        );
});