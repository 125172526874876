// Redux
import { createReducer, isAnyOf } from "@reduxjs/toolkit";
// Types
import { StateProps } from "./types";
// State
import { initialState } from "./initial-state";
// Actions
import {
  generateStudyVisualization,
  getStudyVisualizationBySlice,
  resetStudyVisualization,
  pollStudyVisualization,
  generatingStudyVisualization,
  resetGeneratingStudyVisualization,
} from "./actions";

export const reducer = createReducer<StateProps>(initialState, (builder) => {
  builder.addCase(resetStudyVisualization, () => initialState);
  builder.addCase(generatingStudyVisualization, (state) => ({
    ...state,
    generating: true,
  }));
  builder.addCase(resetGeneratingStudyVisualization, (state) => ({
    ...state,
    generating: false,
  }));
  builder
    // Loading start
    .addMatcher(
      isAnyOf(
        generateStudyVisualization.pending,
        getStudyVisualizationBySlice.pending,
        pollStudyVisualization.pending
      ),
      (state) => ({
        ...state,
        loading: state.loading + 1,
      })
    )
    // Get Study fulfilled
    .addMatcher(
      isAnyOf(
        generateStudyVisualization.fulfilled,
        getStudyVisualizationBySlice.fulfilled,
        pollStudyVisualization.fulfilled
      ),
      (state, action) => ({
        ...state,
        error: action.payload.error,
        data: {
          ...state.data,
          ...action.payload.data,
        },
      })
    )
    // Loading end
    .addMatcher(
      isAnyOf(
        generateStudyVisualization.fulfilled,
        generateStudyVisualization.rejected,
        getStudyVisualizationBySlice.fulfilled,
        getStudyVisualizationBySlice.rejected,
        pollStudyVisualization.fulfilled,
        pollStudyVisualization.rejected
      ),
      (state) => ({
        ...state,
        loading: state.loading > 0 ? state.loading - 1 : 0,
      })
    );
});
