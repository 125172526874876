import { WebSocketConnectionConfig } from "store/middleware/websockets/types";
import { onClose } from "./on-close";
import { onInvalidMessageReceived } from "./on-invalid-message-recieved";
import { onMessage } from "./on-message";
import { SummaryMessageSchema } from "../schemas";

export const summariesWSConnectionConfig: WebSocketConnectionConfig<typeof SummaryMessageSchema> = {
  path: "/summaries/stream/",
  messageSchema: SummaryMessageSchema,
  onMessage: onMessage,
  onClose: onClose,
  onInvalidMessageReceived: onInvalidMessageReceived,
};
