import { signInAnonymously } from "firebase/auth";
import { getAnalytics } from "logic/analytics/analytics";
import { ReactNode, useCallback, useEffect } from "react";
import { getFirebaseAuth } from "../internals/apis/firebase/firebase-auth";
import { useMainApi } from "../internals/apis/main/use-main-api";
import { Logger } from "../internals/logging/logger";
import { useLocalStorage } from "../internals/storage/use-local-storage";
import { useSessionStorage } from "../internals/storage/use-session-storage";
import { TransportFailure } from "../internals/transports/transported-data/transport-failures";
import { TransportPhase } from "../internals/transports/transported-data/transport-phases";
import { useAuthState } from "./auth-state";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { resetCurrentUser } from "store/modules/users/actions";
import { useOngoingWalkthrough } from "logic/hooks/ongoing-walkthrough";
import {
  getLatestWorkspace,
  getWorkspacesList,
  resetWorkspace,
} from "store/modules/workspaces/actions";

export function AuthEffects(props: { children: ReactNode }) {
  const dispatch = useAppDispatch();
  const analytics = getAnalytics();

  const { sessionRef, setSession } = useAuthState();
  const mainApi = useMainApi();

  const localStorage = useLocalStorage();
  const sessionStorage = useSessionStorage();

  const { ongoingWalkthrough, markWalkthroughAsDone } = useOngoingWalkthrough();

  // TODO: CURRENT USER IS NOT UPDATED
  const currentUser = useAppSelector((state) => state.currentUser.data.currentUser);

  const continueLogout = useCallback(async () => {
    if (!sessionRef.current.data) {
      return;
    }

    setSession({
      status: TransportPhase.Loading,
    });

    // on logout, we want to clear all local storage and session storage
    localStorage.wipeAll();
    sessionStorage.wipeAll();

    // except for the ongoing walkthrough
    if (ongoingWalkthrough === false) markWalkthroughAsDone();

    const firebaseAuth = getFirebaseAuth();

    await signInAnonymously(firebaseAuth);
  }, [
    localStorage,
    sessionRef,
    sessionStorage,
    setSession,
    ongoingWalkthrough,
    markWalkthroughAsDone,
  ]);

  useEffect(() => {
    const firebaseAuth = getFirebaseAuth();
    const listener = firebaseAuth.onAuthStateChanged(
      (user) => {
        (async () => {
          if (user) {
            if (sessionRef.current.data?.user.uid !== user.uid) {
              dispatch(resetCurrentUser());

              setSession({
                status: TransportPhase.Done,
                data: {
                  user,
                },
              });
              dispatch(getLatestWorkspace());
              dispatch(getWorkspacesList());
            }
          } else {
            if (sessionRef.current.status === TransportPhase.NotInitialized) {
              setSession({ status: TransportPhase.Loading });

              await signInAnonymously(firebaseAuth);
            } else {
              continueLogout();
              dispatch(resetWorkspace());
            }
          }
        })();
      },
      (error: unknown) => {
        Logger.logError("FrameWithState:onAuthStateChanged:error", error);

        setSession({
          status: TransportFailure.UnexpectedResponse,
          data: sessionRef.current.data,
        });
      }
    );

    return () => {
      listener();
    };
  }, [
    continueLogout,
    localStorage,
    mainApi,
    sessionRef,
    setSession,
    analytics,
    dispatch,
    currentUser,
  ]);

  return <>{props.children}</>;
}
