import { LoadingStatus } from "store/types";
import { StateProps } from "./types";

export const initialState: StateProps = {
  loading: LoadingStatus.none,
  error: undefined,
  data: {
    solutions: undefined,
    selectedSolutions: undefined,
    selectedSolutionId: undefined,
  },
};
