export enum TransportFailure {
  ConnectionFailure = "connection-failure",
  UnexpectedResponse = "unexpected-response",
  AbortedAndDealtWith = "aborted-and-dealt-with",
  Forbidden = "forbidden",
  NotFound = "not-found",
  InternalServerError = "internal-server-error",
  UserUnautorized = "user_unauthorized",
  PreparingSyntheticUsersFailed = "preparing_synthetic_users_failed",
  MembersAlreadyInvited = "member_already_invited_to_workspace",
  MemberAlreadyPartOfWorkspace = "member-already-part-of-workspace",
  WorkspaceSeatsLimitReached = "workspace_seats_limit_reached",
  WorkspaceNotFound = "workspace-not-found",
  WorkspaceInviteNotFound = "workspace_invite_not_found",
  WorkspaceInviteUserNotInvited = "workspace_invite_user_not_the_invited",
  WorkspaceInviteUserAlreadyMember = "workspace_invite_user_already_member",
  InterviewsLimitReached = "interviews_limit_reached",
  PlanUpgradeNeeded = "plan_upgrade_needed",
  SubscriptionNotFound = "subscription_not_found",
  CustomScriptTooManyQuestions = "custom_script_too_many_questions",
  WorkspaceNameAlreadyInUse = "worskpace-name-already-in-use",
}

export function getTransportFailureFromString(
  input: string,
  defaultValue: TransportFailure
): TransportFailure {
  const failureValues = Object.values(TransportFailure) as string[];
  const matchingValue = failureValues.find((value) =>
    input.toLowerCase().includes(value.toLowerCase())
  );
  const matchingKey = Object.keys(TransportFailure).find(
    (key) => TransportFailure[key as keyof typeof TransportFailure] === matchingValue
  );
  return matchingKey
    ? TransportFailure[matchingKey as keyof typeof TransportFailure]
    : defaultValue;
}
