import { createReducer, isAnyOf } from "@reduxjs/toolkit";

import { StateProps } from "./types";

import { initialState } from "./initial-state";

import { redeemSubscription, resetSubscription } from "./actions";

export const reducer = createReducer<StateProps>(initialState, (builder) => {
    builder.addCase(resetSubscription, () => initialState);

    builder
      .addMatcher(isAnyOf(redeemSubscription.pending), (state) => ({
        ...state,
        loading: state.loading + 1,
      }))
      .addMatcher(isAnyOf(redeemSubscription.fulfilled), (state, action) => ({
        ...state,
          ...action.payload,
      }))
      .addMatcher(isAnyOf(redeemSubscription.fulfilled, redeemSubscription.rejected), (state) => ({
        ...state,
        loading: state.loading - 1,
      }));
});
