// Redux
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
// Typings
import { RootState, store } from "store";
import { StateProps, StudyVisualization, StudyVisualizationPreload } from "./types";
// Schemas
import {
  StudyVisualizationPreloadSchema,
  StudyVisualizationSchema,
  StudyVisualizationStatus,
} from "./schemas";
// Initial State
import { initialState } from "./initial-state";
// Main Api
import { getMainApi } from "store/utils/main-api";
// Zod
import { z } from "zod";
import { TransportFailure } from "logic/internals/transports/transported-data/transport-failures";

let polling = 0;
let pollingTO: ReturnType<typeof setTimeout | typeof clearTimeout> = undefined;

/**
 * Generate Synthetic Users
 * Example of an async action / thunk
 * @example await/void dispatch(generateSyntheticUsers({ mainApi, projectId, quantity, audiencesIds, problemsIds }}));
 */
export const generateStudyVisualization = createAsyncThunk<
  Partial<StateProps>,
  {
    studyId: string;
  },
  { state: RootState }
>("study-visualization/generate", async ({ studyId }, { dispatch }) => {
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: string }>>({
    schema: z.object({
      status: z.literal(200),
      body: z.string(),
    }),
    skipParsing: false,
    method: "POST",
    path: `/studyVisualizations/generate/visualization/${studyId}`,
  });

  if (result.failure) {
    error = result.failure;
    return {
      error,
    };
  } else {
    dispatch(generatingStudyVisualization());
    setTimeout(() => {
      dispatch(pollStudyVisualization({ studyId }));
    }, 500);
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    error,
  };
});

/**
 * Generate Synthetic Users
 * Example of an async action / thunk
 * @example await/void dispatch(generateSyntheticUsers({ mainApi, projectId, quantity, audiencesIds, problemsIds }}));
 */
export const pollStudyVisualization = createAsyncThunk<
  Partial<StateProps>,
  {
    studyId: string;
  },
  { state: RootState }
>("study-visualization/poll", async ({ studyId }, { getState, dispatch }) => {
  let data: StateProps["data"] = getState().studyVisualization.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  // clear timeout if pollingTO is defined on init
  if (pollingTO !== undefined) {
    pollingTO = clearTimeout(pollingTO);
  }

  // enable polling
  polling += 1;

  const mainApi = getMainApi();

  // Total two minutes (60 * 2000msecs)
  const result = await mainApi.fetch<
    z.ZodType<
      | {
          status: 200;
          body: StudyVisualizationPreload;
        }
      | {
          status: 404;
          body: { detail: string };
        }
    >
  >({
    schema: z.object({
      status: z.literal(200),
      body: StudyVisualizationPreloadSchema,
    }),
    skipParsing: false,
    method: "GET",
    path: `/studyVisualizations/visualization/${studyId}/done`,
  });

  // Error handling
  if (result.failure) {
    error = result.failure;

    if (result.failure == "not-found") {
      error = undefined;
    }
  } else if (result.response.status === 404) {
    error = undefined;
  } else {
    // Success
    const payload = result.response.body;

    if (payload.status === StudyVisualizationStatus.FAILED) {
      error = payload.message as TransportFailure;
      dispatch(resetGeneratingStudyVisualization());
    } else if (payload.status === StudyVisualizationStatus.RUNNING) {
      dispatch(generatingStudyVisualization());
      pollingTO = setTimeout(() => {
        if (polling) {
          polling -= 1;
          dispatch(pollStudyVisualization({ studyId }));
        }
      }, 10000);
    } else {
      // Status is done
      // disable polling
      polling -= 1;
      if (pollingTO !== undefined) {
        pollingTO = clearTimeout(pollingTO);
      }

      const filteredPreload = {
        ...payload,
        col_slices:
          payload?.col_slices &&
          payload?.col_slices.filter((slice) => slice !== "location" && slice !== "age"),
      };

      data = {
        studyVisualizationPreload: filteredPreload,
      };

      const firstSlice = filteredPreload.col_slices && filteredPreload.col_slices[0];

      if (firstSlice) {
        dispatch(getStudyVisualizationBySlice({ studyId, sliceCol: firstSlice }));
        dispatch(resetGeneratingStudyVisualization());
      }
    }
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Fetch Study by Id
 * Example of an async action / thunk
 * @example await/void dispatch(getStudy({ mainApi, studyId }}));
 */
export const getStudyVisualizationBySlice = createAsyncThunk<
  Partial<StateProps>,
  { studyId: string; sliceCol: string },
  { state: RootState }
>("study-visualization/fetch-by-col-slice", async ({ studyId, sliceCol }, { getState }) => {
  let data: StateProps["data"] = getState().studyVisualization.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: StudyVisualization }>>({
    schema: z.object({
      status: z.literal(200),
      body: StudyVisualizationSchema,
    }),
    skipParsing: false,
    method: "GET",
    path: `/studyVisualizations/visualization/${studyId}/done/${sliceCol}`,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      studyVisualization: result.response.body,
    };
  }
  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Reset Study
 * @example dispatch(resetStudy());
 */
export const generatingStudyVisualization = createAction("study-visualization/generating");

/**
 * Reset Study
 * @example dispatch(resetStudy());
 */
export const resetGeneratingStudyVisualization = createAction(
  "study-visualization/reset-generating"
);

/**
 * Reset Study
 * @example dispatch(resetStudy());
 */
export const resetStudyVisualization = createAction("study-visualization/reset");

export const killStudyVisualizationGenerating = () => {
  polling = 0;
  store.dispatch(resetGeneratingStudyVisualization());
  if (pollingTO !== undefined) {
    pollingTO = clearTimeout(pollingTO);
  }
};
